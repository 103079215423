import {
  OPEN_LOGIN_MODAL,
  OPEN_SIGN_UP_MODAL,
  CLOSE_MODAL,
  LOGIN_UPDATE_MODAL_STAGE,
  USER_LOGGED_IN, UPDATE_USER_ME, UPDATE_USER_ME_PAYMENT,
  UPDATE_LOGIN_DETAILS,
} from './actions';

import { LoginModalStage } from './constants';
import { LOG_OUT } from '../navigation/actions';
import { UPDATE_ONBOARDING_COMPLETE } from '../profile/actions';

const initialState = {
  isModalOpen: false, lastLogin: null, loginDetails: {}, lastLoginModalStage: null, loginModalStage: LoginModalStage.EMAIL, isVerifyingHandler: false, isHandlerVerified: false, hasHandlerBeenChecked: false, mobile_extension: '+1 US', user: null,
};

export const login = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_USER_ME: {
      const { user } = payload;
      return {
        ...state,
        user,
      };
    }

    case UPDATE_ONBOARDING_COMPLETE: {
      const { user } = state;
      user.is_onboarding_complete = true;
      return {
        ...state,
        user,
      };
    }

    case UPDATE_LOGIN_DETAILS: {
      const { loginDetails } = payload;
      const newLoginState = { ...state.loginDetails, ...loginDetails };
      return {
        ...state,
        loginDetails: newLoginState,
      };
    }

    case UPDATE_USER_ME_PAYMENT: {
      const { stripeId, isStripeSetup } = payload;
      const { user } = state;
      user.stripe_payout_id = stripeId;
      user.stripe_payouts_activated = isStripeSetup;
      return {
        ...state,
        user,
      };
    }

    case OPEN_LOGIN_MODAL: {
      return {
        ...state,
        isModalOpen: true,
        loginModalStage: LoginModalStage.EMAIL,
      };
    }
    case OPEN_SIGN_UP_MODAL: {
      return {
        ...state,
        isModalOpen: true,
        loginModalStage: LoginModalStage.EMAIL,
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        isModalOpen: false,
      };
    }

    case LOGIN_UPDATE_MODAL_STAGE: {
      const { stage: updateLoginStage } = payload;
      return {
        ...state,
        loginModalStage: updateLoginStage,
      };
    }

    case USER_LOGGED_IN: {
      const { user } = payload;
      return {
        ...state,
        user,
        lastLogin: Date.now(),
      };
    }

    case LOG_OUT: {
      return {
        ...state,
        user: null,
        loginDetails: {},
        loginModalStage: LoginModalStage.EMAIL,
        isModalOpen: false,
      };
    }

    default:
      return state;
  }
};
