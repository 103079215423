import { RDRouteDirectory } from '../utilities/RDRouteDirectory';
import { history } from '../store';

export const getSelectedSection = (state) => state.navigation.selectedNavSection;

export const getCurrentPath = (state) => RDRouteDirectory.getCleanedPath(state.router.location.pathname);

export const getPreSearchPath = (state) => {
  if (state.navigation.preSearchRoute) {
    return RDRouteDirectory.getCleanedPath(state.navigation.preSearchRoute);
  }
  return null;
};

export const getCurrentQuery = (state) => {
  if (state.router.location.query) {
    return state.router.location.query;
  }
  return null;
};

export const getIsWebPushModalOpen = (state) => false;
// return state.navigation.isWebPushModalOpen
