import React from 'react';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { RDSchedule } from '../models/RDSchedule';
import { RDUser } from '../models/RDUser';
import { fetchAgenda } from '../schedule/thunks';
import { store } from '../store';
import { getLoggedInUser } from '../onboarding/selectors';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';

export const RDNotify = {
  displayScheduleNotification(scheduleNotificationData) {
    const currentUser = getLoggedInUser(store.getState());
    store.dispatch(fetchAgenda(currentUser));

    const { type } = scheduleNotificationData;
    const schedule = new RDSchedule(scheduleNotificationData.schedule);

    if (!schedule) {
      console.log('Failed to display schedule notification');
      return;
    }

    let message = (
      <span>
        <b>
          @
          {schedule.requester.username}
        </b>
        {' '}
        has requested a conversation on
        {' '}
        <b>
          &quot;
          {schedule.topic.title}
          &quot;
        </b>
        {' '}
        with you.
      </span>
    );
    if (schedule.connector) {
      message = (
        <span>
          <b>
            @
            {schedule.connector.username}
          </b>
          {' '}
          has requested a conversation on
          {' '}
          <b>
            &quot;
            {schedule.topic.title}
            &quot;
          </b>
          {' '}
          between you and
          {' '}
          {schedule.getCompanion().username}
          .
        </span>
      );
    }

    if (type === 'confirm') {
      const fromUser = new RDUser(scheduleNotificationData.fromUser);
      message = (
        <span>
          <b>
            @
            {fromUser.username}
          </b>
          {' '}
          has confirmed your request to discuss
          {' '}
          <b>
            &quot;
            {schedule.topic.title}
            &quot;
          </b>
          .
        </span>
      );
    } else if (type === 'update') {
      const fromUser = new RDUser(scheduleNotificationData.fromUser);
      message = (
        <span>
          <b>
            @
            {fromUser.username}
          </b>
          {' '}
          has updated their availability for the conversation on
          {' '}
          <b>
            &quot;
            {schedule.topic.title}
            &quot;
          </b>
          {' '}
          with you.
        </span>
      );
    }

    const messageStyle = {
      width: '100%',
      backgroundColor: 'clear',
      fontFamily: 'Avenir',
      color: 'black',
    };

    const messageElement = (
      <div
        onClick={() => {
          store.dispatch(push(RDRouteDirectory.dispatch));
        }}
        style={messageStyle}
      >
        {message}
      </div>
    );

    toast(messageElement, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 10000,
      progressClassName: 'rt-color-progress-clipboard !important',
    });
  },

  displayClipboardNotification(message) {
    toast.info(`📋 ${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 5000,
    });
  },

  displaySuccessNotification(message, time) {
    const emojis = ['🎉', '🥳'];
    if (time === null) {
      time = 5000;
    }
    const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
    toast.success(`${randomEmoji} ${message}`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: time,
    });
  },

  displayFailureNotification(message) {
    const emojis = ['😵', '😳', '😞', '😓', '😔'];
    const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
    console.log(`${randomEmoji} ${message}`);
    toast.error(`${randomEmoji} ${message}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  },

  displayWarningNotification(message) {
    const emojis = ['🤔', '😐', '😕', '😶'];
    const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
    toast.warning(`${randomEmoji} ${message}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  },
};
