import {
  ADD_ARCHIVE_TO_LOOKUP,
  LOAD_ARCHIVES_SUCCESS,
  TOGGLE_IS_LOADING_ARCHIVES,
  TOGGLE_EMBED_MODAL,
  EDIT_CONVERSATION,
} from './actions';

const initialState = {
  archivedConversations: [], isFetchingArchives: false, archiveLookup: {}, isEmbedModalOpen: false,
};
export const listen = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_ARCHIVE_TO_LOOKUP: {
      const { archive } = payload;
      return {
        ...state,
        archiveLookup: {
          ...state.archiveLookup,
          [archive.uuid]: archive,
        },
      };
    }

    case TOGGLE_IS_LOADING_ARCHIVES: {
      const { isLoading } = payload;
      return {
        ...state,
        isFetchingArchives: isLoading,
      };
    }

    case TOGGLE_EMBED_MODAL: {
      const { isOpen } = payload;
      return {
        ...state,
        isEmbedModalOpen: isOpen,
      };
    }

    case LOAD_ARCHIVES_SUCCESS: {
      const { archives } = payload;

      const archiveDictionary = {};
      for (let i = 0; i < archives.length; i++) {
        const archive = archives[i];
        archiveDictionary[archive.uuid] = archive;
      }

      return {
        ...state,
        archiveLookup: archiveDictionary,
        isFetchingArchives: false,
        archivedConversations: archives.reverse(),
      };
    }

    default:
      return state;
  }
};
