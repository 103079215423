export default class RDAudioPlayer {
  private static instance: RDAudioPlayer;

  private audioPlayer = new Audio();

  public static getInstance(): RDAudioPlayer {
    if (!RDAudioPlayer.instance) {
      RDAudioPlayer.instance = new RDAudioPlayer();
    }
    return RDAudioPlayer.instance;
  }

  public getAudioPlayer():HTMLAudioElement {
    return this.audioPlayer;
  }

  public loadSource(src:string) {
    console.log('🎵 Loading Audio Player');
    this.audioPlayer.src = src;
    this.audioPlayer.load();
  }

  public play() {
    console.log('🎵 Play');
    this.audioPlayer.play();
  }

  public pause() {
    console.log('🎵 Pause');
    this.audioPlayer.pause();
  }

  public isPaused():boolean {
    console.log('🎵 Is Paused');
    return this.audioPlayer.paused;
  }

  public seekAndPlay(ms:number) {
    console.log(`🎵 Seek and Play: ${ms}`);
    this.audioPlayer.currentTime = ms;
    this.audioPlayer.play();
  }

  public stop() {
    console.log('🎵 Stop Player');
    this.audioPlayer.pause();
    this.audioPlayer.ontimeupdate = null;
    this.audioPlayer.oncanplay = null;
    this.audioPlayer.onended = null;
    this.audioPlayer.src = '';
  }

  public getCurrentTime():number {
    return this.audioPlayer.currentTime;
  }
}
