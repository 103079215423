export const UPDATE_LIVE_USER_IDS = 'UPDATE_LIVE_USER_IDS';
export const updateLiveUserIds = (liveUserIds) => ({
  type: UPDATE_LIVE_USER_IDS,
  payload: { liveUserIds },
});

export const UPDATE_LIVE_USERS = 'UPDATE_LIVE_USERS';
export const updateLiveUsers = (liveUsers) => ({
  type: UPDATE_LIVE_USERS,
  payload: { liveUsers },
});

export const UPDATE_LIVE_REQUESTS = 'UPDATE_LIVE_REQUESTS';
export const updateLiveRequests = (liveRequests) => ({
  type: UPDATE_LIVE_REQUESTS,
  payload: { liveRequests },
});

export const LOAD_TALK_TOPICS_IN_PROGRESS = 'LOAD_TALK_TOPICS_IN_PROGRESS';
export const loadTalkTopicsInProgress = () => ({
  type: LOAD_TALK_TOPICS_IN_PROGRESS,
});

export const LOAD_TALK_TOPICS_SUCCESS = 'LOAD_TALK_TOPICS_SUCCESS';
export const loadTalkTopicsSuccess = (topics) => ({
  type: LOAD_TALK_TOPICS_SUCCESS,
  payload: { topics },
});

export const LOAD_TALK_TOPICS_FAILURE = 'LOAD_TALK_TOPICS_FAILURE';
export const loadTalkTopicsFailure = () => ({
  type: LOAD_TALK_TOPICS_FAILURE,
});

export const OPEN_CREATE_TOPIC_MODAL = 'OPEN_CREATE_TOPIC_MODAL';
export const openCreateTopicModal = () => ({
  type: OPEN_CREATE_TOPIC_MODAL,
});

export const CLOSE_CREATE_TOPIC_MODAL = 'CLOSE_CREATE_TOPIC_MODAL';
export const closeCreateTopicModal = () => ({
  type: CLOSE_CREATE_TOPIC_MODAL,
});

export const OPEN_TOPIC_MODAL = 'OPEN_TOPIC_MODAL';
export const openTopicModal = (topic) => ({
  type: OPEN_TOPIC_MODAL,
  payload: { topic },
});

export const CLOSE_TOPIC_MODAL = 'CLOSE_TOPIC_MODAL';
export const closeTopicModal = () => ({
  type: CLOSE_TOPIC_MODAL,
});

export const ADD_TOPIC_TO_LOOKUP = 'ADD_TOPIC_TO_LOOKUP';
export const addTopicToLookup = (topic) => ({
  type: ADD_TOPIC_TO_LOOKUP,
  payload: { topic },
});

export const ADD_USER_TO_LOOKUP = 'ADD_USER_TO_LOOKUP';
export const addUserToLookup = (user) => ({
  type: ADD_USER_TO_LOOKUP,
  payload: { user },
});

export const CREATED_NEW_TOPIC = 'CREATED_NEW_TOPIC';
export const createdNewTopic = (topic) => ({
  type: CREATED_NEW_TOPIC,
  payload: { topic },
});

export const UPDATE_PUBLISHER_VOLUME = 'UPDATE_PUBLISHER_VOLUME';
export const updatePublisherVolume = (volume) => ({
  type: UPDATE_PUBLISHER_VOLUME,
  payload: { volume },
});

export const SET_PUBLISHER_ACTIVE = 'SET_PUBLISHER_ACTIVE';
export const setPublisherActive = () => ({
  type: SET_PUBLISHER_ACTIVE,
});

export const SET_PUBLISHER_INACTIVE = 'SET_PUBLISHER_INACTIVE';
export const setPublisherInactive = () => ({
  type: SET_PUBLISHER_INACTIVE,
});
