import {LOAD_REVIEWS_SUCCESS, RATE_REVIEW, TOGGLE_IS_LOADING_REVIEWS} from "./actions";
import {reviewConversation} from "../conversation/actions";

const initialState = {reviewConversations: [], isFetchingReviews: false};

export const review = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {

    case RATE_REVIEW: {
      const {review: review} = payload;
      for (let i=0; i<state.reviewConversations.length; i++) {
        let reviewLocal = state.reviewConversations[i]
        if (reviewLocal[`id`] === review.reviewId) {
          reviewLocal[`recipient_approval`] = review.approval ? 1 : 0
        }
      }
      return {...state,
        reviewConversations: state.reviewConversations
      }
    }

    case TOGGLE_IS_LOADING_REVIEWS: {
      const {isLoading: isLoading} = payload;
      return {...state,
        isFetchingReviews: isLoading
      }
    }

    case LOAD_REVIEWS_SUCCESS: {
      const {reviews: reviews} = payload;
      console.log("LOAD REVIEWS SUCCESS")
      console.log(reviews)

      return {...state,
        isFetchingReviews: false,
        reviewConversations: reviews,
      }
    }

    default:
      return state;
  }
}