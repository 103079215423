export const OPEN_CONVERSATION_MODAL = 'OPEN_CONVERSATION_MODAL';
export const openConversationModal = () => ({
  type: OPEN_CONVERSATION_MODAL,
});

export const CLOSE_CONVERSATION_MODAL = 'CLOSE_CONVERSATION_MODAL';
export const closeConversationModal = () => ({
  type: CLOSE_CONVERSATION_MODAL,
});

export const TOGGLE_CONVERSATION_NOTIFICATION_MODAL = 'TOGGLE_CONVERSATION_NOTIFICATION_MODAL';
export const toggleConversationNotificationModal = (isOpen) => ({
  type: TOGGLE_CONVERSATION_NOTIFICATION_MODAL,
  payload: { isOpen },
});

export const SHOW_CONVERSATION_NOTIFICATION_MODAL = 'SHOW_CONVERSATION_NOTIFICATION_MODAL';
export const showConversationNotificationModal = (user, topic) => ({
  type: SHOW_CONVERSATION_NOTIFICATION_MODAL,
  payload: { user, topic },
});

export const CLEAR_CONVERSATION_NOTIFICATION_MODAL = 'CLEAR_CONVERSATION_NOTIFICATION_MODAL';
export const clearConversationNotificationModal = () => ({
  type: CLEAR_CONVERSATION_NOTIFICATION_MODAL,
});

export const UPDATE_CONVERSATION_STATUS = 'UPDATE_CONVERSATION_STATUS';
export const updateConversationStatus = (status, openTokSessionId) => ({
  type: UPDATE_CONVERSATION_STATUS,
  payload: { status, openTokSessionId },
});

export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';
export const setCurrentConversation = (conversation) => ({
  type: SET_CURRENT_CONVERSATION,
  payload: { conversation },
});

export const CLEAR_CURRENT_CONVERSATION = 'CLEAR_CURRENT_CONVERSATION';
export const clearCurrentConversation = () => ({
  type: CLEAR_CURRENT_CONVERSATION,
});

export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const createConversation = (currentUser, partner, topic) => ({
  type: CREATE_CONVERSATION,
  payload: { currentUser, partner, topic },
});

export const START_CONVERSATION = 'START_CONVERSATION';
export const startConversation = (currentUser, partner, topic) => ({
  type: START_CONVERSATION,
  payload: { currentUser, partner, topic },
});

export const END_CONVERSATION = 'END_CONVERSATION';
export const endConversation = (conversationId) => ({
  type: END_CONVERSATION,
  payload: { conversationId },
});

export const REVIEW_CONVERSATION = 'REVIEW_CONVERSATION';
export const reviewConversation = (status, openTokSessionId) => ({
  type: REVIEW_CONVERSATION,
  payload: { status, openTokSessionId },
});
