import { useEffect, useState } from 'react';
import { axiosInstance, isResponseSuccess, RDApi } from '../api/api';
import { RDComment } from '../models/RDComment';
import { RDConversation } from '../models/RDConversation';

const useCommentsForConversation = (conversation:RDConversation|null, forceProd:boolean) => {
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState<RDComment[]>([]);

  const fetchCommentsForConversation = async (pConversation:RDConversation) => {
    setIsLoading(true);
    const params = { conversation_id: pConversation.conversationId };

    let endpoint = RDApi.fetchCommentsForConversation;
    if (forceProd) {
      endpoint = `https://radical.overtonelabs.com/${RDApi.fetchCommentsForConversation}`;
    }
    const res = await axiosInstance.get(endpoint, { params });
    setIsLoading(false);
    if (isResponseSuccess(res)) {
      const commentJsons = res.data.comments;
      const cleanedComments = [];
      for (let i = 0; i < commentJsons.length; i += 1) {
        const commentJson = commentJsons[i];
        const comment = new RDComment(commentJson);
        cleanedComments.push(comment);
      }
      cleanedComments.sort((a, b) => ((a.timestamp < b.timestamp) ? -1 : 1));
      setComments(cleanedComments);
    } else {
      console.log('Failed to fetch comments for conversation ');
    }
  };

  useEffect(() => {
    if (conversation && conversation.conversationId) {
      console.log(`Fetching comments for conversation: ${conversation.conversationId}`);
      fetchCommentsForConversation(conversation).catch(console.error);
    }
  }, [conversation]);
  return [isLoading, comments] as const;
};

export default useCommentsForConversation;
