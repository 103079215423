export const TOGGLE_IS_LOADING_MATCHES = 'TOGGLE_IS_LOADING_MATCHES';
export const toggleIsLoadingMatches = (isLoading) => ({
  type: TOGGLE_IS_LOADING_MATCHES,
  payload: {isLoading}
});

export const LOAD_MATCHES_SUCCESS = 'LOAD_MATCHES_SUCCESS'
export const loadMatchesSuccess = (matches) => ({
  type: LOAD_MATCHES_SUCCESS,
  payload: {matches}
});