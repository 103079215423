import { reset } from 'redux-form';
import { replace } from 'connected-react-router';
import {
  changeNavSection,
  logOut,
} from './actions';

import { axiosInstance, isResponseSuccess, RDApi } from '../api/api';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';

export const changeNavigationSection = (section) => async (dispatch) => {
  dispatch(changeNavSection(section));
};

export const logOutOfRadical = () => async (dispatch) => {
  console.log('Logging out...');
  dispatch(reset('onboarding'));
  dispatch(replace(RDRouteDirectory.index));
  dispatch(logOut());
};

export const userEnteredApp = (user) => async () => {
  try {
    const res = await axiosInstance.post(RDApi.enteredApp, { user_id: user.userId });
    if (isResponseSuccess(res)) {
      console.log('Successfully entered app.');
    }
  } catch (err) {
    console.log(err);
  }
};
