export const LoginModalStage = {
  EMAIL: 'EMAIL',
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  INVITE_CODE: 'INVITE_CODE',
  MOBILE_NUMBER: 'MOBILE_NUMBER',
  MOBILE_VERIFICATION: 'MOBILE_VERIFICATION',
  USER_REGISTRATION: 'USER_REGISTRATION',
  COMPLETE: 'COMPLETE',
  PHONE_TO_EMAIL: 'PHONE_TO_EMAIL',
};
