import { RDUser } from './RDUser';
import { RDTopic } from './RDTopic';
import { RDConversation } from './RDConversation';

export const RDScheduleStates = {
  confirmed: 'confirmed',
  edit: 'edit',
  view: 'view',
};

export class RDSchedule {
  constructor(json) {
    this.scheduleId = json.id;
    this.uuid = json.uuid;
    this.partner = new RDUser(json.partner);
    this.requester = new RDUser(json.requester);
    this.requestTime = new Date(parseInt(json.request_time) * 1000);

    if (json.connector) {
      this.connector = new RDUser(json.connector);
    }

    if (json.topic) {
      this.topic = new RDTopic(json.topic);
    }

    if (json.conversation) {
      this.conversation = new RDConversation(json.conversation);
    }

    if (json.alt_time_1 && json.alt_time_1 !== 'NaN') {
      this.altTime1 = new Date(parseInt(json.alt_time_1) * 1000);
    } else {
      this.altTime1 = null;
    }

    if (json.alt_time_2 && json.alt_time_2 !== 'NaN') {
      this.altTime2 = new Date(parseInt(json.alt_time_2) * 1000);
    } else {
      this.altTime2 = null;
    }

    if (json.confirmed_time) {
      this.confirmedTime = new Date(json.confirmed_time * 1000);
    } else {
      this.confirmedTime = null;
    }

    if (json.last_update_user) {
      this.lastUpdatedUser = new RDUser(json.last_update_user);
    } else {
      this.lastUpdatedUser = null;
    }

    if (json.waiting_user) {
      this.waitingUser = new RDUser(json.waiting_user);
    } else {
      this.waitingUser = null;
    }

    if (json.date_updated && json.date_updated !== 'NaN') {
      this.dateUpdated = new Date(parseInt(json.date_updated) * 1000);
    } else {
      this.dateUpdated = null;
    }

    if (json.date_created && json.date_created !== 'NaN') {
      this.dateCreated = new Date(parseInt(json.date_created) * 1000);
    } else {
      this.dateCreated = null;
    }

    this.rawJson = json;
  }

  getCompanion() {
    if (this.partner.isMe()) {
      return this.requester;
    }
    return this.partner;
  }

  hasConnector() {
    if (this.connector === undefined || !this.connector) {
      return false;
    }
    return true;
  }

  isConnectorMe() {
    console.log('Checking is connector me');
    console.log(this);
    console.log(this.connector);
    console.log(this.hasConnector());
    if (this.hasConnector() && this.connector.isMe()) {
      return true;
    }
    return false;
  }

  isConfirmed() {
    if (this.confirmedTime !== null) {
      return true;
    }
    return false;
  }

  getRawJson() {
    const { rawJson } = this;
    if (this.confirmedTime !== null) {
      rawJson.confirmed_time = Math.floor(this.confirmedTime.getTime() / 1000);
    }

    if (this.requestTime !== null) {
      rawJson.request_time = Math.floor(new Date(this.requestTime).getTime() / 1000);
    }

    if (this.altTime1 !== null) {
      rawJson.alt_time_1 = Math.floor(this.altTime1.getTime() / 1000);
    }

    if (this.altTime2 !== null) {
      rawJson.alt_time_2 = Math.floor(this.altTime2.getTime() / 1000);
    }
    return rawJson;
  }

  getScheduleMode() {
    let scheduleMode = RDScheduleStates.view;
    if (this.confirmedTime !== null) {
      scheduleMode = RDScheduleStates.confirmed;
    } else if (this.connector && this.lastUpdatedUser === null) {
      scheduleMode = RDScheduleStates.edit;
    } else if (this.lastUpdatedUser && !this.lastUpdatedUser.isMe()) {
      scheduleMode = RDScheduleStates.view;
    } else if (this.lastUpdatedUser && this.lastUpdatedUser.isMe()) {
      scheduleMode = RDScheduleStates.edit;
    } else if (this.requester.isMe()) {
      scheduleMode = RDScheduleStates.edit;
    } else {
      scheduleMode = RDScheduleStates.view;
    }
    return scheduleMode;
  }
}
