import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {RDColors} from '../utilities/RDColors.js'


const OuterDiv = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0px auto;
  
.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${RDColors.pink};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
  background-color: ${RDColors.blue} !important;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
`

const RDBounceLoader = ({style}) => {

  let loader = (
      <OuterDiv style={style}>
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </OuterDiv>
  )


  return loader
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(RDBounceLoader);
