import { push } from 'connected-react-router';
import { change } from 'redux-form';
import { axiosInstance, isResponseSuccess, RDApi } from '../api/api';
import { RDNotify } from '../global/RDNotify';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';
import {
  addScheduleToLookup,
  deleteScheduleConfirmed,
  deleteSchedulePending,
  loadAgendaSuccess,
  toggleIsLoadingAgenda,
  confirmScheduleLocal, editScheduleLocal, updateAgendaNewItemsCount,
} from './actions';

export const syncFormToSchedule = (schedule) => async (dispatch) => {
  console.log('SYNCING SCHEDULE');
  console.log(schedule);
  console.log(schedule.requestTime.toISOString());

  if (schedule.requestTime) {
    dispatch(change('new_schedule', 'requestTime', schedule.requestTime.toISOString()));
  } else {
    dispatch(change('new_schedule', 'requestTime', null));
  }

  if (schedule.altTime1) {
    dispatch(change('new_schedule', 'alt1RequestTime', schedule.altTime1.toISOString()));
  } else {
    dispatch(change('new_schedule', 'alt1RequestTime', null));
  }

  if (schedule.altTime2) {
    dispatch(change('new_schedule', 'alt2RequestTime', schedule.altTime2.toISOString()));
  } else {
    dispatch(change('new_schedule', 'alt2RequestTime', null));
  }
};

export const fetchScheduleForId = (scheduleId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(RDApi.fetchScheduleForId, { params: { scheduleId } });
    if (isResponseSuccess(res)) {
      const { schedule } = res.data;
      console.log('Successfully fetched schedule');
      dispatch(addScheduleToLookup(schedule));
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchAgenda = (user) => async (dispatch) => {
  const data = {
    user_id: user.userId,
  };

  dispatch(toggleIsLoadingAgenda(true));

  try {
    const res = await axiosInstance.get(RDApi.retrieveSchedule, { params: data });
    if (isResponseSuccess(res)) {
      dispatch(loadAgendaSuccess(
        { pending: res.data.pending, confirmed: res.data.confirmed, activity: res.data.activity },
      ));
      dispatch(updateAgendaNewItemsCount());
    } else {
      dispatch(toggleIsLoadingAgenda(false));
      RDNotify.displayFailureNotification('Failed to load agenda.');
    }
  } catch (err) {
    dispatch(toggleIsLoadingAgenda(false));
    RDNotify.displayFailureNotification('Failed to load agenda.');
    throw err;
  }
};

export const deleteSchedule = (user, schedule, isPending) => async (dispatch) => {
  const data = {
    user_id: user.userId,
    schedule_id: schedule.scheduleId,
  };

  try {
    const res = await axiosInstance.post(RDApi.deleteSchedule, data);
    if (isResponseSuccess(res)) {
      if (schedule.isConfirmed()) {
        RDNotify.displaySuccessNotification('Successfully cancelled scheduled conversation.');
      } else {
        RDNotify.displaySuccessNotification('Successfully cancelled scheduled conversation request.');
      }
      console.log(`Pushing to: ${RDRouteDirectory.dispatch}`);
      dispatch(push(RDRouteDirectory.dispatch));
      if (isPending) {
        dispatch(deleteSchedulePending(schedule));
      } else {
        dispatch(deleteScheduleConfirmed(schedule));
      }
    } else {
      RDNotify.displayFailureNotification('Failed to delete scheduled conversation.');
    }
  } catch (err) {
    RDNotify.displayFailureNotification('Failed to delete scheduled conversation.');
    throw err;
  }
};

export const rejectSchedule = (user, schedule) => async (dispatch) => {
  const data = {
    user_id: user.userId,
    schedule_id: schedule.scheduleId,
  };

  try {
    const res = await axiosInstance.post(RDApi.rejectSchedule, data);
    if (isResponseSuccess(res)) {
      dispatch(push(RDRouteDirectory.dispatch));
      RDNotify.displaySuccessNotification('Successfully rejected conversation.');
    } else {
      RDNotify.displayFailureNotification('Failed to reject conversation.');
    }
  } catch (err) {
    RDNotify.displayFailureNotification('Failed to reject conversation.');
    throw err;
  }
};

export const confirmSchedule = (user, confirmedTime, schedule) => async (dispatch) => {
  const data = {
    user_id: user.userId,
    schedule_id: schedule.scheduleId,
    confirmed_time: Math.round(confirmedTime.getTime() / 1000),
    to_user_id: schedule.getCompanion().userId,
  };

  try {
    const res = await axiosInstance.post(RDApi.confirmSchedule, data);
    if (isResponseSuccess(res)) {
      schedule.confirmedTime = confirmedTime;
      dispatch(confirmScheduleLocal(schedule));
      dispatch(push(RDRouteDirectory.dispatch));
      RDNotify.displaySuccessNotification(`Successfully confirmed schedule between you and ${schedule.getCompanion().username}.`);
    } else {
      RDNotify.displayFailureNotification(`Failed to confirm schedule between you and ${schedule.getCompanion().username}.`);
    }
  } catch (err) {
    RDNotify.displayFailureNotification(`Failed to confirm schedule between you and ${schedule.getCompanion().username}.`);
    throw err;
  }
};

export const editSchedule = (
  user,
  partner,
  schedule,
  requestTime,
  altTime1,
  altTime2,
) => async (dispatch) => {
  const data = {
    user_id: user.userId,
    schedule_id: schedule.scheduleId,
    request_time: Math.round(requestTime.getTime() / 1000),
    to_user_id: schedule.getCompanion().userId,
  };

  schedule.requestTime = requestTime;
  schedule.lastUpdatedUser = user;
  schedule.waitingUser = schedule.getCompanion();

  if (altTime1) {
    data.alt1_time = Math.round(altTime1.getTime() / 1000);
    schedule.altTime1 = altTime1;
  }

  if (altTime2) {
    data.alt2_time = Math.round(altTime2.getTime() / 1000);
    schedule.altTime2 = altTime2;
  }

  try {
    const res = await axiosInstance.post(RDApi.editSchedule, data);
    if (isResponseSuccess(res)) {
      RDNotify.displaySuccessNotification(`Successfully updated schedule between you and ${partner.username}.`);
      dispatch(push(RDRouteDirectory.dispatch));
      dispatch(editScheduleLocal(schedule));
    } else {
      RDNotify.displayFailureNotification(`Failed to update schedule between you and ${partner.username}.`);
    }
  } catch (err) {
    RDNotify.displayFailureNotification(`Failed to update schedule between you and ${partner.username}.`);
    throw err;
  }
};

export const scheduleConversation = (
  user,
  partner,
  topic,
  requestTime,
  altTime1,
  altTime2,
) => async (dispatch) => {
  const data = {
    user_id: user.userId,
    partner_id: partner.userId,
    topic_id: topic.topicId,
    request_time: Math.round(requestTime.getTime() / 1000),
  };

  if (altTime1) {
    data.alt1_time = Math.round(altTime1.getTime() / 1000);
  }

  if (altTime2) {
    data.alt2_time = Math.round(altTime2.getTime() / 1000);
  }

  try {
    const res = await axiosInstance.post(RDApi.newSchedule, data);
    if (isResponseSuccess(res)) {
      RDNotify.displaySuccessNotification(`Successfully scheduled conversation between you and ${partner.username}.`);
      dispatch(push(RDRouteDirectory.dispatch));
    } else {
      RDNotify.displayWarningNotification(`Scheduled conversation already exists between you and ${partner.username}.`);
    }
  } catch (err) {
    RDNotify.displayFailureNotification('Failed to schedule conversation.');
    throw err;
  }
};
