import moment from 'moment-timezone';

if (!String.prototype.replaceAll) {
  console.log('No replace all - using custom');
  String.prototype.replaceAll = function (search, replacement) {
    return this.split(search).join(replacement);
  };
}

export const RDTextUtils = {

  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
  },

  convertSecondsToTimerDisplay(seconds) {
    const noMinutes = Math.trunc(seconds / 60);
    const noSeconds = Math.trunc(seconds % 60);

    let noMinutesString = `${noMinutes}`;
    if (noMinutes < 10) {
      noMinutesString = `0${noMinutes}`;
    }

    let noSecondsString = `${noSeconds}`;
    if (noSeconds < 10) {
      noSecondsString = `0${noSeconds}`;
    }

    const timerDisplayString = `${noMinutesString}:${noSecondsString}`;
    return timerDisplayString;
  },

  convertTimestampToFullDateString(timestamp) {
    const date = new Date(timestamp * 1000);
    return moment(date).format('MMMM Do YYYY, h:mm a');
  },

  convertTimestampToPrettyDateString(timestamp) {
    const date = new Date(timestamp * 1000);

    const currentDate = moment();
    const diff = currentDate.diff(date);

    const diffSeconds = Math.round(diff / 1000);

    if (diffSeconds < 60) {
      return 'Just now';
    } if (diffSeconds < (60 * 60)) {
      const noMinutes = Math.round(diffSeconds / 60);
      return `${noMinutes}m ago`;
    } if (diffSeconds < (60 * 60 * 24)) {
      const noHours = Math.round(diffSeconds / 60 / 60);
      return `${noHours}h ago`;
    } if (diffSeconds < (60 * 60 * 24 * 30)) {
      const noHours = Math.round(diffSeconds / 60 / 60 / 24);
      return `${noHours}d ago`;
    }
    return moment(date).format('MM/DD/YY');
  },
};
