export const RDRouteDirectory = {
  talk: '/talk',
  listen: '/listen',
  review: '/review',
  dispatch: '/agenda',
  profile: '/profile',
  createTopic: '/create/topic',
  requestConversation: '/request',
  editProfile: '/profile/edit',
  interests: '/profile/interests',
  search: '/search',
  home: '/matches',
  index: '/',

  getRouteForTopic(topic) {
    return `/topic/${topic.uuid}`;
  },

  getRouteForSchedule(schedule) {
    return `/schedule/${schedule.uuid}`;
  },

  getRouteForNewSchedule(topic, user) {
    return `/topic/${topic.uuid}/schedule?partner=${user.username}`;
  },

  getRouteForConversation(topic, user) {
    return `/conversation?partner=${user.username}&topicId=${topic.uuid}`;
  },

  getRouteForOpenConversationWithUUID(conversationUUID) {
    return `/conversation/${conversationUUID}/talk`;
  },

  getRouteForOpenConversation(conversation) {
    return `/conversation/${conversation.uuid}/talk`;
  },

  getRouteForConversationWithTopicUUID(topicUUID, user) {
    return `/conversation?partner=${user.username}&topicId=${topicUUID}`;
  },

  getRouteForArchivedConversation(conversation) {
    return `/conversation/${conversation.uuid}`;
  },

  getRouteForArchivedConversationUUID(conversationUUID) {
    return `/conversation/${conversationUUID}`;
  },

  getRouteForConversationDonation(conversation) {
    return `/conversation/${conversation.uuid}/donate`;
  },

  getRouteForConversationEdit(conversation) {
    return `/conversation/${conversation.uuid}/edit`;
  },

  getRouteForProfile(user) {
    return `/user/${user.username}`;
  },

  getRouteForProfileFollowing(user) {
    return `/user/${user.username}/following`;
  },

  getRouteForProfileFollowers(user) {
    return `/user/${user.username}/followers`;
  },

  isPathForProfile(path) {
    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length === 3 && pathParts[1] === 'user') {
      return { isRouteProfile: true, username: pathParts[2] };
    }
    return { isRouteProfile: false, username: null };
  },

  isPathForEmbeddedConversation(path) {
    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length >= 3 && pathParts[1] === 'embed') {
      return { isRouteEmbeddedConversation: true, conversationUUID: pathParts[2] };
    }
    return { isRouteEmbeddedConversation: false, conversationUUID: null };
  },

  isPathForEditProfile(path) {
    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length >= 3 && pathParts[1] === 'profile' && pathParts[2] === 'edit') {
      return { isRouteEditProfile: true };
    }
    return { isRouteEditProfile: false };
  },

  isPathForInterests(path) {
    const pathParts = path.replaceAll(' ', '').split('/');
    // console.log(pathParts)
    if (pathParts.length >= 3 && pathParts[1] === 'profile' && pathParts[2] === 'interests') {
      return { isRouteInterests: true };
    }
    return { isRouteInterests: false };
  },

  isPathForFollowersOrFollowing(path) {
    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length >= 4 && pathParts[1] === 'user'
      && (pathParts[3] === 'followers' || pathParts[3] === 'following')) {
      return { isRouteForFollow: true, username: pathParts[2], type: pathParts[3] };
    }
    return { isRouteForFollow: false, username: null, type: null };
  },

  isPathForSchedule(path) {
    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length >= 3 && pathParts[1] === 'schedule') {
      return { isRouteSchedule: true, scheduleId: pathParts[2] };
    }
    return { isRouteSchedule: false, scheduleId: null };
  },

  isPathForTopic(path) {
    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length >= 3 && pathParts[1] === 'topic' && pathParts[pathParts.length - 1] !== 'schedule') {
      return { isRouteTopic: true, topicId: pathParts[2] };
    }
    return { isRouteTopic: false, topicId: null };
  },

  isPathForNewSchedule(path, query) {
    const pathParts = path.replaceAll(' ', '').split('/');
    const username = query.partner;

    if (pathParts.length >= 4 && pathParts[1] === 'topic' && pathParts[pathParts.length - 1] === 'schedule') {
      return { isRouteNewSchedule: true, topicId: pathParts[2], username };
    }
    return { isRouteNewSchedule: false, topicId: null };
  },

  isPathForArchivedConversation(path) {
    // console.log("Path: "+path)
    if (!path) {
      return { isRouteArchivedConversation: false, conversationId: null };
    }

    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length === 3 && pathParts[1] === 'conversation') {
      return { isRouteArchivedConversation: true, conversationId: pathParts[2] };
    }
    return { isRouteArchivedConversation: false, conversationId: null };
  },

  isPathForDonation(path) {
    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length >= 3 && pathParts[1] === 'conversation' && pathParts[3] === 'donate') {
      return { isRouteDonation: true, conversationId: pathParts[2] };
    }
    return { isRouteDonation: false, conversationId: null };
  },

  isPathForConversationEdit(path) {
    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length >= 3 && pathParts[1] === 'conversation' && pathParts[3] === 'edit') {
      return { isRouteConversationEdit: true, conversationId: pathParts[2] };
    }
    return { isRouteConversationEdit: false, conversationId: null };
  },

  isPathForOpenConversation(path) {
    if (!path) {
      return { isRouteOpenConversation: false, conversationUUID: null };
    }

    const pathParts = path.replaceAll(' ', '').split('/');
    if (pathParts.length === 4 && pathParts[1] === 'conversation' && pathParts[3] === 'talk') {
      return { isRouteOpenConversation: true, conversationUUID: pathParts[2] };
    }
    return { isRouteOpenConversation: false, conversationUUID: null };
  },

  isPathForConversation(path, query) {
    const pathParts = path.replaceAll(' ', '').split('/');
    const username = query.partner;
    const { topicId } = query;

    if (pathParts.length >= 2 && pathParts[1] === 'conversation' && username != null && username.length > 0 && topicId != null && topicId.length > 0) {
      return { isRouteConversation: true, topicId, username };
    }
    return { isRouteConversation: false, topicId: null, username: null };
  },

  getCleanedPath(pathname) {
    // console.log("Get cleaned path")
    // console.log(pathname)

    let pathParts = [];
    if (pathname.replaceAll) {
      pathParts = pathname.replaceAll(' ', '').split('/');
    } else {
      pathParts = pathname.replace(/ /g, '').split('/');
    }

    const cleanedPathParts = pathParts.filter((x) => x !== '');

    // console.log(cleanedPathParts)
    let returnPath = '/';
    for (let i = 0; i < cleanedPathParts.length; i++) {
      if (cleanedPathParts[i].length === 0) {
        continue;
      }

      if (i === cleanedPathParts.length - 1) {
        returnPath += cleanedPathParts[i];
      } else {
        returnPath += `${cleanedPathParts[i]}/`;
      }
    }
    // console.log("Cleaned path: " + returnPath)
    return returnPath;
  },
};
