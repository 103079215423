import { RDTopic } from '../models/RDTopic';
import { RDUser } from '../models/RDUser';
import { RDConversation } from '../models/RDConversation';
import { getCurrentPath, getCurrentQuery } from '../navigation/selectors';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';
import { getLoggedInUser } from '../onboarding/selectors';
import { getUserForUsername } from '../talk/selectors';

export const getIsConversationModalOpen = (state) => {
  const currentPath = getCurrentPath(state);
  const currentQuery = getCurrentQuery(state);
  const conversationPathCheck = RDRouteDirectory.isPathForConversation(currentPath, currentQuery);
  const openConversationPathCheck = RDRouteDirectory.isPathForOpenConversation(currentPath);

  if (openConversationPathCheck.isRouteOpenConversation) {
    return true;
  }

  if (conversationPathCheck.isRouteConversation) {
    return true;
  }
  return false;
};

export const getSelectedUser = (state) => {
  const currentPath = getCurrentPath(state);
  const currentQuery = getCurrentQuery(state);
  const currentUser = getLoggedInUser(state);

  const conversationRouteCheck = RDRouteDirectory.isPathForConversation(currentPath, currentQuery);
  const newScheduleRouteCheck = RDRouteDirectory.isPathForNewSchedule(currentPath, currentQuery);

  if (state.conversation.notificationUser !== null) {
    return new RDUser(state.conversation.notificationUser);
  } if (conversationRouteCheck.isRouteConversation) {
    if (conversationRouteCheck.username !== currentUser.username) {
      return getUserForUsername(conversationRouteCheck.username, state);
    }
    return state.conversation.selected;
  } if (newScheduleRouteCheck.isRouteNewSchedule) {
    if (newScheduleRouteCheck.username !== currentUser.username) {
      return getUserForUsername(newScheduleRouteCheck.username, state);
    }
    return null;
  }
  return null;

  // return new RDUser(state.conversation.selectedUser)
};

export const getConversationStatus = (state) => state.conversation.conversationStatus;

export const getConversationNotificationUser = (state) => new RDUser(state.conversation.notificationUser);

export const getConversationNotificationTopic = (state) => new RDTopic(state.conversation.notificationTopic);

export const getIsConversationNotificationModalOpen = (state) => state.conversation.isConversationNotificationModalOpen;

export const getCurrentConversation = (state) => {
  console.log('Getting current conversation.');
  if (state.conversation.currentConversation != null) {
    console.log(state.conversation.currentConversation);
    return new RDConversation(state.conversation.currentConversation);
  }
  console.log('Current conversation is empty');
  return null;
};
