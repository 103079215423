import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { configureStore, history } from './store';
import { RDConfig } from './config';
import RDEmbeddedPlayer from './embed/RDEmbeddedPlayer';

const store = configureStore();
const persistor = persistStore(store);

if (RDConfig.isEmbed) {
  ReactDOM
    .render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <RDEmbeddedPlayer />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root'),
    );
} else {
  (async () => {
    const App = await import('./App.js');
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <PersistGate
            loading={<div>Loading...</div>}
            persistor={persistor}
          >
            <App.default />
          </PersistGate>
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root'),
    );
  })();
}
