import { push } from 'connected-react-router';
import { reset } from 'redux-form';
import {
  loadTalkTopicsInProgress,
  loadTalkTopicsSuccess,
  loadTalkTopicsFailure,
  createdNewTopic,
  openTopicModal,
  closeTopicModal,
  setPublisherActive,
  setPublisherInactive,
  addTopicToLookup,
  addUserToLookup,
} from './actions';

import {
  updateMyProfile,
} from '../onboarding/actions';

import { axiosInstance, isResponseSuccess, RDApi } from '../api/api';
import { RDNotify } from '../global/RDNotify';
import { RDTopic } from '../models/RDTopic';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';
import { RDUser } from '../models/RDUser';
import { fetchAgenda } from '../schedule/thunks';
import { updateAgendaNewItemsCount } from '../schedule/actions';

async function fetchLatestPositions(user) {
  const res = await axiosInstance.post(RDApi.fetchTalkTopics, { user_id: user.id });
  if (isResponseSuccess(res)) {
    const latestTopics = res.data.positions;
    return latestTopics;
  }
  RDNotify.displayFailureNotification('Failed to fetch latest topics.');
  throw new Error('Failed to fetch latest topics');
}

async function fetchFeaturedPositions() {
  const res = await axiosInstance.get(RDApi.fetchFeaturedTopics, null);
  if (isResponseSuccess(res)) {
    const featuredTopics = res.data.topics;
    return featuredTopics;
  }
  RDNotify.displayFailureNotification('Failed to fetch featured topics.');
  throw new Error('Failed to fetch featured topics');
}

export const loadTalkTabTopics = (user) => async (dispatch) => {
  console.log('loadTalkTabTopics');
  try {
    dispatch(loadTalkTopicsInProgress());
    const [latestTopics, featuredTopics] = await Promise.all([
      fetchLatestPositions(user),
      fetchFeaturedPositions(),
    ]);
    console.log(latestTopics);
    dispatch(loadTalkTopicsSuccess({ latest: latestTopics, featured: featuredTopics }));
  } catch (err) {
    RDNotify.displayFailureNotification('Failed to fetch topics.');
    dispatch(loadTalkTopicsFailure());
  }
};

export const fetchUserForUserId = (userId) => async (dispatch) => {
  try {
    const currentUser = RDUser.getCurrentUser();
    const params = { userId, requestingUserName: currentUser.username };
    const res = await axiosInstance.get(RDApi.fetchUserForUserId, { params });
    if (isResponseSuccess(res)) {
      const { user } = res.data;
      const userModel = new RDUser(user);

      if (userModel.isMe()) {
        dispatch(updateMyProfile(user));
      }

      dispatch(addUserToLookup(user));
      return userModel;
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchUserForUsername = (username) => async (dispatch) => {
  console.log(`Fetching: ${username}`);
  try {
    const currentUser = RDUser.getCurrentUser();
    const params = { username, requestingUserName: currentUser.username };
    const res = await axiosInstance.get(RDApi.fetchUserForUsername, { params });
    console.log(res);
    if (isResponseSuccess(res)) {
      console.log('Fetched user');
      const { user } = res.data;
      console.log(user);
      const userModel = new RDUser(user);
      console.log(user);

      if (userModel.isMe()) {
        dispatch(updateMyProfile(user));
      }
      dispatch(addUserToLookup(user));
      return user;
    }
    return null;
  } catch (err) {
    console.log(err);
  }
};

export const fetchTopicForId = (topicId) => async (dispatch) => {
  console.log(`Fetching topic for Id: ${topicId}`);
  try {
    const res = await axiosInstance.get(RDApi.fetchTopicForId, {
      params: { topicId, isUUID: false },
    });
    console.log(res);
    if (isResponseSuccess(res)) {
      const { topic } = res.data;
      dispatch(addTopicToLookup(topic));
      console.log('Successfully dispatched to lookup');
      return new RDTopic(topic);
    }
    return null;
  } catch (err) {
    console.log(err);
  }
};

export const fetchTopicForUUID = (topicUUID) => async (dispatch) => {
  console.log(`Fetching topic for UUID: ${topicUUID}`);
  try {
    const res = await axiosInstance.get(RDApi.fetchTopicForId, {
      params: { topicId: topicUUID, isUUID: true },
    });
    console.log('Retrieve topic :)');
    if (isResponseSuccess(res)) {
      const { topic } = res.data;
      dispatch(addTopicToLookup(topic));
    }
  } catch (err) {
    console.log(err);
  }
};

export const requestConversation = (
  creator,
  partner,
  partnerEmailInfo,
  times,
  topic,
  isTopicPublic,
  existingTopicId,
  currentUser,
  isNow,
) => async (dispatch) => {
  try {
    const data = {
      username: creator.username,
      userId: creator.userId,
      requesterUsername: currentUser.username,
      requesterId: currentUser.userId,
      times,
      isNow,
    };

    if (partner) {
      data.partnerUsername = partner.username;
      data.partnerId = partner.userId;
    } else if (partnerEmailInfo) {
      data.partnerEmail = partnerEmailInfo.email;
      data.partnerName = partnerEmailInfo.name;
      data.partnerUsername = partnerEmailInfo.username;
    }

    if (topic && topic.length > 0) {
      data.topicText = topic;
      data.isTopicPublic = isTopicPublic;
    }

    if (existingTopicId) {
      data.existingTopicId = existingTopicId;
    }

    const res = await axiosInstance.post(RDApi.requestConversation, data);
    if (isResponseSuccess(res)) {
      if (isTopicPublic) {
        dispatch(loadTalkTabTopics(currentUser));
      }

      dispatch(fetchAgenda(currentUser));
      dispatch(updateAgendaNewItemsCount());
      if (isNow) {
        const { conversation } = res.data;
        if (conversation) {
          dispatch(push(RDRouteDirectory.getRouteForOpenConversation(conversation)));
        }
      } else {
        dispatch(push(RDRouteDirectory.dispatch));
      }

      RDNotify.displaySuccessNotification('Successfully requested conversation.');
    } else {
      if (res.data.status === 'failure' && res.data.reason === 'already_exists') {
        let user1Username = `@${data.requesterUsername}`;
        if (user1Username === `@${currentUser.username}`) {
          user1Username = 'you';
        }

        let user2Username = `@${data.partnerUsername}`;
        if (user2Username === `@${currentUser.username}`) {
          user2Username = 'you';
        }
        RDNotify.displayWarningNotification(`An conversation has already been scheduled between ${user1Username} and ${user2Username}`);
        return;
      }
      RDNotify.displayFailureNotification('Failed to request conversation.');
    }
  } catch (err) {
    RDNotify.displayFailureNotification('Failed to request conversation.');
  }
};

export const openCreateTopicPanel = () => async (dispatch) => {
  dispatch(push(RDRouteDirectory.createTopic));
};

export const closeCreateTopicPanel = () => async (dispatch) => {
  dispatch(push(RDRouteDirectory.talk));
};

export const openConversationRequestModal = () => async (dispatch) => {
  dispatch(push(RDRouteDirectory.requestConversation));
};

export const openTopicPanel = (topic) => async (dispatch) => {
  dispatch(openTopicModal(topic));
  dispatch(push(RDRouteDirectory.getRouteForTopic(topic)));
};

export const closeTopicPanel = () => async (dispatch) => {
  dispatch(push(RDRouteDirectory.talk));
  dispatch(closeTopicModal());
};

export const activatePublisher = () => async (dispatch) => {
  dispatch(setPublisherActive());
};

export const deactivatePublisher = () => async (dispatch) => {
  dispatch(setPublisherInactive());
};

export const registerWebPushSubscriptionForUser = (sub, user, baseUrl, userAgent) => async (dispatch) => {
  console.log('Registering web push token for user');
  try {
    const data = {
      subscription: sub,
      user_id: user.userId,
      base_url: baseUrl,
      user_agent: userAgent,
    };
    console.log(data);
    const res = await axiosInstance.post(RDApi.registerWebPushTokenForUser, data);
    console.log(res);
    if (isResponseSuccess(res)) {
      console.log('Successfully registered web push subscription for user.');
    } else {
      RDNotify.displayFailureNotification('Failed to register web push subscription for user.');
    }
  } catch (err) {
    RDNotify.displayFailureNotification('Failed to register web push subscription for user.');
    console.log(err);
    throw err;
  }
};

export const createNewTopic = (topic, interests, user) => async (dispatch) => {
  console.log('creating topic');
  try {
    const data = {
      user_id: user.userId,
      topic,
      interests,
    };
    console.log(data);
    const res = await axiosInstance.post(RDApi.createTopic, data);
    if (isResponseSuccess(res)) {
      const { topic } = res.data;
      RDNotify.displaySuccessNotification('Successfully created new topic!');
      dispatch(createdNewTopic(topic));
      dispatch(push(RDRouteDirectory.talk));
      dispatch(reset('new_topic'));
    } else {
      RDNotify.displayFailureNotification('Failed to create new topic');
      throw new Error('Failed to create new topic');
    }
  } catch (err) {
    RDNotify.displayFailureNotification('Failed to create new topic');
    console.log(err);
    throw err;
  }
};
