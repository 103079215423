import {
  LOAD_TALK_TOPICS_SUCCESS,
  LOAD_TALK_TOPICS_FAILURE,
  LOAD_TALK_TOPICS_IN_PROGRESS,
  OPEN_CREATE_TOPIC_MODAL,
  CLOSE_CREATE_TOPIC_MODAL,
  CREATED_NEW_TOPIC,
  OPEN_TOPIC_MODAL,
  CLOSE_TOPIC_MODAL,
  UPDATE_PUBLISHER_VOLUME,
  UPDATE_LIVE_USER_IDS,
  ADD_TOPIC_TO_LOOKUP,
  ADD_USER_TO_LOOKUP, UPDATE_USER_ME, UPDATE_LIVE_USERS, UPDATE_LIVE_REQUESTS,
} from './actions';

const initialState = {
  isLoadingTopics: false, latestTopics: [], featuredTopics: [], topicLookup: {}, topicIdLookup: {}, userLookup: {}, userIdLookup: {}, isCreateTopicModalOpen: false, isTopicModalOpen: false, selectedTopic: null, publisherVolume: 0, liveUserIds: {}, liveRequests: [],
};

export const talk = (state = initialState, action) => {
  const { type, payload } = action;

  // console.log(`TALK: ${type}`)
  switch (type) {
    case ADD_TOPIC_TO_LOOKUP: {
      const { topic } = payload;
      return {
        ...state,
        topicLookup: {
          ...state.topicLookup,
          [topic.uuid]: topic,
        },
        topicIdLookup: {
          ...state.topicIdLookup,
          [topic.id]: topic,
        },
      };
    }

    case ADD_USER_TO_LOOKUP: {
      const { user } = payload;
      return {
        ...state,
        userLookup: {
          ...state.userLookup,
          [user.handler]: user,
        },
        userIdLookup: {
          ...state.userIdLookup,
          [user.id]: user,
        },
      };
    }

    case UPDATE_LIVE_USER_IDS: {
      const { liveUserIds } = payload;
      return {
        ...state,
        liveUserIds,
      };
    }

    case UPDATE_LIVE_REQUESTS: {
      const { liveRequests } = payload;
      return {
        ...state,
        liveRequests,
      };
    }

    case UPDATE_LIVE_USERS: {
      const { liveUsers } = payload;
      return {
        ...state,
        liveUsers,
      };
    }

    case UPDATE_PUBLISHER_VOLUME: {
      const { volume } = payload;
      return {
        ...state,
        publisherVolume: volume,
      };
    }

    case OPEN_CREATE_TOPIC_MODAL: {
      return {
        ...state,
        isCreateTopicModalOpen: true,
      };
    }

    case CLOSE_CREATE_TOPIC_MODAL: {
      return {
        ...state,
        isCreateTopicModalOpen: false,
      };
    }

    case OPEN_TOPIC_MODAL: {
      const { topic } = payload;
      return {
        ...state,
        isTopicModalOpen: true,
        selectedTopic: topic.rawJson,
      };
    }

    case CLOSE_TOPIC_MODAL: {
      return {
        ...state,
        isTopicModalOpen: false,
      };
    }

    case LOAD_TALK_TOPICS_IN_PROGRESS: {
      return {
        ...state,
        isLoadingTopics: true,
      };
    }

    case LOAD_TALK_TOPICS_FAILURE: {
      return {
        ...state,
        isLoadingTopics: false,
      };
    }

    case LOAD_TALK_TOPICS_SUCCESS: {
      const { topics } = payload;

      const topicDictionary = {};
      const topicIdDictionary = {};

      const userDictionary = {};
      const userIdDictionary = {};

      // Pre-populate with existing entries first.
      for (const key in state.topicLookup) {
        topicDictionary[key] = state.topicLookup[key];
      }

      for (const key in state.topicIdLookup) {
        topicIdDictionary[key] = state.topicIdLookup[key];
      }

      for (const key in state.userLookup) {
        userDictionary[key] = state.userLookup[key];
      }

      for (const key in state.userIdLookup) {
        userIdDictionary[key] = state.userIdLookup[key];
      }

      const allTopics = topics.featured.concat(topics.latest);
      for (let i = 0; i < allTopics.length; i++) {
        const topic = allTopics[i];
        topicDictionary[topic.uuid] = topic;
        topicIdDictionary[topic.id] = topic;
        userDictionary[topic.creator.handler] = topic.creator;
        userIdDictionary[topic.creator.userId] = topic.creator;
      }

      return {
        ...state,
        isLoadingTopics: false,
        featuredTopics: topics.featured,
        latestTopics: topics.latest,
        topicLookup: topicDictionary,
        userLookup: userDictionary,
        userIdLookup: userIdDictionary,
        topicIdLookup: topicIdDictionary,
      };
    }

    case CREATED_NEW_TOPIC: {
      const { topic } = payload;
      return {
        ...state,
        isCreateTopicModalOpen: false,
        latestTopics: [topic].concat(state.latestTopics),
      };
    }

    default:
      return state;
  }
};
