import {getLoggedInUser} from "../onboarding/selectors"
import {getLiveUserIds} from "../talk/selectors"
import {store} from "../store"
import {RDIconURIs} from "../utilities/RDIconURIs";
import React from "react";
import styled from "styled-components";
import {RDColors} from "../utilities/RDColors";

const VerifiedBadge = styled.span`
  font-family: FontelloBadge;
  margin-right:0.2em;
  height:100%;
  line-height:100%;
  color: ${RDColors.purpleBlue};
`

const UsernameDisplay = styled.span`
  display: inline-flex;
  align-items: center;
`

export class RDUser {

  constructor(json) {
    this.userId = json[`id`]
    this.name = json[`name`]
    this.username = json[`handler`]
    this.firstName = json[`first_name`]
    this.lastName = json[`last_name`]
    this.noConversations = json[`no_conversations`]
    this.openTokSessionId = json[`opentok_session_id`]
    this.points = json[`points`]
    this.profileImageUrl = json[`profile_image_url`]
    this.starRating = json[`star_rating`]
    this.stripeId = json[`stripe_payout_id`]
    this.isVerified = json[`is_verified`]
    this.following = 0
    this.follower = 0
    this.isFollowing = json[`is_following`]

    if (json[`follow_count`]) {
      this.following = json[`follow_count`]
    }

    if (json[`follower_count`]) {
      this.follower = json[`follower_count`]
    }


    if (json[`is_onboarding_complete`]) {
      this.isOnboardingComplete = json[`is_onboarding_complete`]
    } else {
      this.isOnboardingComplete = false
    }

    if (json[`stripe_payout_activated`] === 1) {
      this.stripePayoutActivated = true
    } else {
      this.stripePayoutActivated = false
    }

    if (json[`interests`] && json[`interests`].length > 0) {
      this.interests = json[`interests`]
    } else {
      this.interests = []
    }

    if (json[`bio`]) {
      this.bio = json[`bio`]
    } else {
      this.bio = ""
    }

    this.rawJson = json
    this.thumbnail = json[`thumbnail`]
  }

  getProfileImageUrl () {
    if (this.profileImageUrl) {
      return this.profileImageUrl
    } else {
      return RDIconURIs.defaultProfileImage
    }
  }

  getThumbnail () {
    if (this.thumbnail) {
      return this.thumbnail
    } else if (this.profileImageUrl) {
      return this.profileImageUrl
    } else {
      return RDIconURIs.defaultProfileImage
    }
  }

  getDisplayUsername () {
    return <UsernameDisplay>
      {this.isVerified &&
      <VerifiedBadge></VerifiedBadge>
      }
      {this.username}
    </UsernameDisplay>
  }

  getDisplayName () {
    return <UsernameDisplay>
      {this.isVerified &&
        <VerifiedBadge></VerifiedBadge>
      }
      {this.name}
    </UsernameDisplay>
  }

  static getCurrentUser() {
    let state = store.getState()
    let currentUser = getLoggedInUser(state)
    return currentUser
  }

  static isUserInArray(user, array) {
    let isUserInArray = false
    for (let i=0; i<array.length; i++) {
      let arrayUser = array[i]
      if (arrayUser.userId === user.userId) {
        isUserInArray = true
        break
      }
    }
    return isUserInArray;
  }

  isMe() {
    let state = store.getState()
    let currentUser = getLoggedInUser(state)
    if (currentUser !== null && currentUser.userId === this.userId) {
      return true
    } else {
      return false
    }
  }

  getFollowerString() {
    if (this.follower > 1) {
      return `${this.follower} followers`
    } else if (this.follower === 1 ) {
      return `${this.follower} follower`
    } else {
      return `No followers`
    }
  }

  getFollowingString() {
    if (this.following > 0) {
      return `${this.following} following`
    } else {
      return `No following`
    }
  }

  isLive () {
    let state = store.getState()
    let liveUserIds = getLiveUserIds(state)
    if (liveUserIds[this.userId]) {
      return true
    } else {
      return false
    }
  }
}

