import axios from 'axios';
import { RDConfig, RDAppMode } from '../config';

if (RDConfig.appMode === RDAppMode.staging) {
  axios.defaults.baseURL = 'http://54.151.74.221:8081';
} else if (RDConfig.appMode === RDAppMode.production) {
  axios.defaults.baseURL = 'https://radical.overtonelabs.com';
} else {
  axios.defaults.baseURL = 'http://localhost:8081';
}

export const axiosInstance = axios;
export const RDApi = {

  // Utils
  getS3UploadUrl: '/utils/get_s3_upload_url',

  // Embed
  registerEmbed: '/embed/register',

  // Onboarding
  sendVerificationCode: '/send_verification_code',
  verifyPhoneNumber: '/verify_phone_number',
  verifyHandler: '/verify_handler',
  registerUserPhone: '/register_user_phone',
  registerUserEmail: '/login/email/register',
  sendVerificationEmail: '/login/email',
  verifyEmail: '/login/email/verify',
  connectPhoneToEmail: '/login/email/connect_phone',

  // User
  fetchUserForUsername: 'user',
  fetchUserForUserId: 'user',
  fetchUserForEmail: 'user',
  registerWebPushTokenForUser: 'user/register_web_push',
  enteredApp: 'user/entered_app',
  updateUserProfile: 'user',
  updateUserInterests: 'user/interests',
  fetchUserInterests: 'user/interests',
  updateUserFollow: 'user/follow',
  fetchUserFollowing: 'user/follows',
  fetchUserFollower: 'user/followers',
  markOnboardingCompleted: 'user/onboarding',
  nudgeUserToConnectPayments: 'user/nudge_payment',

  // Matches
  getMatches: '/api/matches',

  // Payment
  requestStripeCheckout: 'payment/stripe_checkout',
  registerUserOnStripe: 'payment/stripe_register_user',
  checkStripeUserRegistration: 'payment/check_stripe_registration',
  getStripeDashboardLink: 'payment/get_dashboard_link',

  // Topics
  fetchTopicForId: 'topic',
  fetchTalkTopics: 'position/square',
  fetchFeaturedTopics: 'position/featured',
  createTopic: 'position',
  fetchFeaturedUsers: 'position/featured_users',
  fetchTopicsForUser: 'position/user',
  toggleTopicActivation: 'position/activate',
  deleteTopic: 'topic',

  // OpenTok
  getOpenTokCredentials: 'get_opentok_credentials',

  // Review
  retrieveReviews: 'retrieve_reviews',
  rateReview: 'rate_review',

  // Conversation (Live)
  getConversation: 'conversation',
  reviewConversation: 'conversation/review',
  startConversation: 'conversation/start',
  createConversation: 'conversation/create',
  enterConversation: 'conversation/enter',
  closeConversation: 'conversation/close',
  toggleConversationActivation: 'conversation/activate',
  deleteConversation: 'conversation/delete',
  saveStreamIdForConversation: 'conversation/streamId',
  makeConversationPrivate: 'conversation/make_private',
  saveStreamId: 'conversation/streamId',
  requestConversation: 'conversation/request',
  conversationDefaultUsers: 'conversation/default_users',
  getLocalConversationUploadUrl: 'utils/local_conversation_upload_url',

  // Conversation (Archive/Listen)
  fetchArchivedConversationsForUser: 'conversation/archive',
  fetchArchivesForSquare: 'conversation/archive',
  fetchArchivedConversationForUUID: 'conversation',
  updateConversation: 'conversation',
  markListenForConversation: 'conversation/listen',
  likeConversation: 'conversation/like',
  commentOnConversation: 'conversation/comment',
  deleteComment: 'conversation/comment',
  fetchCommentsForConversation: 'conversation/comment',
  download: '/conversation/download',
  startRecording: 'conversation/archive/start',
  stopRecording: 'conversation/archive/stop',

  // Schedule
  newSchedule: 'schedule/new',
  deleteSchedule: 'schedule/delete',
  confirmSchedule: 'schedule/confirm',
  retrieveSchedule: 'schedule/dispatch',
  editSchedule: 'schedule/edit',
  rejectSchedule: 'schedule/reject',
  fetchScheduleForId: 'schedule',

  // Search
  search: '/search/search',

};

export const isResponseSuccess = function (res) {
  if (res.status !== 200) {
    return false;
  }
  if (res.data.status === 'success') {
    return true;
  }
  return false;
};
