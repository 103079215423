import { useEffect, useState } from 'react';
import { axiosInstance } from '../api/api';
import { RDConversation } from '../models/RDConversation';

interface WordItem {
  startTime: string;
  endTime: string,
  confidence: number,
  word: string,
  pos: string
}

interface WordData {
  count: number;
  items: WordItem[],
}

interface InsightDictionary {
  [word: string]: WordData;
}

const useInsightsForConversation = (
  conversation:RDConversation|null,
  query: {themes:string},
  themeLimit:number = 20,
) => {
  let mNounArray:[string, number][] = [];
  let mPartnerInterjectionArray:[string, number][] = [];
  let mCreatorInterjectionArray:[string, number][] = [];
  let mFullInsights:InsightDictionary = {};
  let mCreatorWordInsights:InsightDictionary = {};
  let mPartnerWordInsights:InsightDictionary = {};

  const [areInsightsAvailable, setAreInsightsAvailable] = useState(false);
  const [fullInsights, setFullInsights] = useState({});
  const [creatorInterjectionArray, setCreatorInterjectionArray] = useState<[string, number][]>([]);
  const [partnerInterjectionArray, setPartnerInterjectionArray] = useState<[string, number][]>([]);
  const [themeArray, setThemeArray] = useState<[string, number][]>([]);

  const convertWordCountObjectIntoSortedArray = (pDataObject:{[key:string]:any}) => {
    const dataObject = pDataObject;
    const dataKeyValues:[string, number][] = Object.keys(dataObject).map(
      (key) => [key, dataObject[key]],
    );
    dataKeyValues.sort((first, second) => second[1] - first[1]);
    return dataKeyValues;
  };

  const convertWordCountObjectIntoSortedNounArray = (pDataObject:{[key:string]:number}) => {
    const boringNouns = [
      'people',
      'part',
      'space',
      'sense',
      'situation',
      'idea',
      'type',
      'types',
      'person',
      'place',
      'question',
      'individuals',
      'state',
      'thing',
      'sort',
      'things',
      'lot',
      'way',
      'example',
    ];
    const dataObject = pDataObject;
    const dataValues:[string, number][] = Object.keys(dataObject).map((key) => {
      let score = dataObject[key] * (key.length * 0.8);
      if (boringNouns.includes(key)) {
        score *= 0;
      }
      return [key, score];
    });
    dataValues.sort((first, second) => second[1] - first[1]);
    return dataValues;
  };

  const populateFullInsightsWithUserInsights = (userInsights:InsightDictionary) => {
    Object.entries(userInsights).forEach((e) => {
      const word = e[0];
      const wordData = e[1] as WordData;
      if (word in mFullInsights) {
        mFullInsights[word].count += wordData.count;
        mFullInsights[word].items = mFullInsights[word].items.concat(wordData.items);
      } else {
        mFullInsights[word] = wordData;
      }
    });
  };

  const fetchInsightsForConversation = async (pConversation:RDConversation) => {
    const res = await axiosInstance.get(pConversation.insightsDataUrl);

    const creatorData = res.data.creator;
    const partnerData = res.data.partner;

    mCreatorWordInsights = {};
    mPartnerWordInsights = {};

    mCreatorWordInsights = creatorData.wordInsights;
    mPartnerWordInsights = partnerData.wordInsights;

    mFullInsights = {};
    mFullInsights = JSON.parse(JSON.stringify(mCreatorWordInsights));
    populateFullInsightsWithUserInsights(mPartnerWordInsights);

    const creatorInterjections = creatorData.POSCountLookUp.INTJ;
    const partnerInterjections = partnerData.POSCountLookUp.INTJ;

    const creatorNouns = creatorData.POSCountLookUp.NOUN;
    const partnerNouns = partnerData.POSCountLookUp.NOUN;
    const totalNouns = JSON.parse(JSON.stringify(creatorNouns));
    Object.entries(partnerNouns).forEach((e) => {
      const word = e[0];
      const count = e[1];
      if (word in totalNouns) {
        totalNouns[word] += count;
      } else {
        totalNouns[word] = count;
      }
    });

    mNounArray = convertWordCountObjectIntoSortedNounArray(totalNouns);
    if (mNounArray.length > themeLimit) {
      mNounArray = mNounArray.slice(0, themeLimit);
    }

    mCreatorInterjectionArray = convertWordCountObjectIntoSortedArray(creatorInterjections);
    if (mCreatorInterjectionArray.length > 6) {
      mCreatorInterjectionArray = mCreatorInterjectionArray.slice(0, 6);
    }

    mPartnerInterjectionArray = convertWordCountObjectIntoSortedArray(partnerInterjections);
    if (mPartnerInterjectionArray.length > 6) {
      mPartnerInterjectionArray = mPartnerInterjectionArray.slice(0, 6);
    }

    if (query && query.themes && query.themes.length > 0) {
      const customThemes = query.themes.split(',');
      const newNounArray:[string, number][] = [];

      for (let i = 0; i < customThemes.length; i += 1) {
        const theme = customThemes[i];
        if (mFullInsights[theme]) {
          newNounArray.push([theme, 1.0]);
        }
      }

      if (newNounArray.length > 0) {
        mNounArray = newNounArray;
      }
    }

    setFullInsights(mFullInsights);
    setCreatorInterjectionArray(mCreatorInterjectionArray);
    setPartnerInterjectionArray(mPartnerInterjectionArray);
    setThemeArray(mNounArray);
    setAreInsightsAvailable(true);
  };

  useEffect(() => {
    if (conversation && conversation.insightsDataUrl) {
      console.log(`Fetching insights for conversation: ${conversation.conversationId}`);
      fetchInsightsForConversation(conversation).catch(console.error);
    }
  }, [conversation]);
  return [
    areInsightsAvailable,
    fullInsights,
    themeArray,
    creatorInterjectionArray,
    partnerInterjectionArray,
  ] as const;
};

export default useInsightsForConversation;
