import moment from 'moment';
import { getCurrentPath, getCurrentQuery } from '../navigation/selectors';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';
import { RDTopic } from '../models/RDTopic';
import { RDSchedule } from '../models/RDSchedule';
import { getLocalTopicForTopicUUID } from '../talk/selectors';
import { RDNotification } from '../models/RDNotification';

export const getRequestTime = (state) => {
  if (state.form != null && state.form.new_schedule != null && state.form.new_schedule.values != null && state.form.new_schedule.values.requestTime != null) {
    return state.form.new_schedule.values.requestTime;
  }
  return null;
};

export const getAlt1RequestTime = (state) => {
  if (state.form != null && state.form.new_schedule != null && state.form.new_schedule.values != null && state.form.new_schedule.values.alt1RequestTime != null) {
    return state.form.new_schedule.values.alt1RequestTime;
  }
  return null;
};

export const getAlt2RequestTime = (state) => {
  if (state.form != null && state.form.new_schedule != null && state.form.new_schedule.values != null && state.form.new_schedule.values.alt2RequestTime != null) {
    return state.form.new_schedule.values.alt2RequestTime;
  }
  return null;
};

export const getLastTimeOnNotificationTab = (state) => {
  const lastTimeOnNotificationTab = state.schedule.lastTimeOnNotification;
  return lastTimeOnNotificationTab;
};

export const getAgendaNewItemsCount = (state) => {
  const { agendaNewItems } = state.schedule;
  return agendaNewItems;
};

export const getActivityLog = (state) => {
  const activityLog = state.schedule.activityLog.map((rawNotification) => new RDNotification(rawNotification));
  return activityLog;
};

export const getPendingConversations = (state) => {
  const pendingConversations = state.schedule.pendingConversations.map((rawSchedule) => new RDSchedule(rawSchedule));
  return pendingConversations;
};

export const getScheduledConversations = (state) => {
  const scheduledConversations = state.schedule.scheduledConversations.map(
    (rawSchedule) => new RDSchedule(rawSchedule),
  );
  return scheduledConversations;
};

export const getScheduleForScheduleId = (scheduleId, state) => {
  let schedule = null;
  if (state.schedule.scheduleLookup[scheduleId]) {
    schedule = new RDSchedule(state.schedule.scheduleLookup[scheduleId]);
  } else {
    console.log("Can't find schedule locally.");
  }
  return schedule;
};

export const getSelectedSchedule = (state) => {
  // Retrieve topic from URL
  const currentPath = getCurrentPath(state);
  const schedulePathCheck = RDRouteDirectory.isPathForSchedule(currentPath);
  if (schedulePathCheck.isRouteSchedule) {
    const schedule = getScheduleForScheduleId(schedulePathCheck.scheduleId, state);
    return schedule;
  }
  return null;
};

export const getIsFetchingAgenda = (state) => state.schedule.isFetchingAgenda;

export const getIsScheduleModalOpen = (state) => {
  const currentPath = getCurrentPath(state);
  const currentQuery = getCurrentQuery(state);

  const newSchedulePathCheck = RDRouteDirectory.isPathForNewSchedule(currentPath, currentQuery);
  const schedulePathCheck = RDRouteDirectory.isPathForSchedule(currentPath);

  if (newSchedulePathCheck.isRouteNewSchedule || schedulePathCheck.isRouteSchedule) {
    return true;
  }
  return false;
};
