export const UPDATE_MY_TOPICS = 'UPDATE_MY_TOPICS';
export const updateMyTopics = (topics) => ({
  type: UPDATE_MY_TOPICS,
  payload: { topics },
});

export const UPDATE_MY_ARCHIVES= 'UPDATE_MY_ARCHIVES';
export const updateMyArchives = (conversations) => ({
  type: UPDATE_MY_ARCHIVES,
  payload: { conversations },
});

export const UPDATE_ONBOARDING_COMPLETE= 'UPDATE_ONBOARDING_COMPLETE';
export const updateOnboardingComplete = () => ({
  type: UPDATE_ONBOARDING_COMPLETE
});
