export const TOGGLE_IS_LOADING_AGENDA= 'TOGGLE_IS_LOADING_AGENDA';
export const toggleIsLoadingAgenda = (isLoading) => ({
  type: TOGGLE_IS_LOADING_AGENDA,
  payload: {isLoading}
});


export const UPDATE_AGENDA_NEW_ITEMS_COUNT = 'UPDATE_AGENDA_NEW_ITEMS_COUNT'
export const updateAgendaNewItemsCount = () => ({
  type: UPDATE_AGENDA_NEW_ITEMS_COUNT
});

export const UPDATE_LAST_TIME_ON_NOTIFICATION_TAB = 'UPDATE_LAST_TIME_ON_NOTIFICATION_TAB'
export const updateLastTimeOnNotificationTab = () => ({
  type: UPDATE_LAST_TIME_ON_NOTIFICATION_TAB
});

export const LOAD_AGENDA_SUCCESS= 'LOAD_AGENDA_SUCCESS'
export const loadAgendaSuccess = (agenda) => ({
  type: LOAD_AGENDA_SUCCESS,
  payload: {agenda}
});

export const ADD_SCHEDULE_TO_LOOKUP= 'ADD_SCHEDULE_TO_LOOKUP';
export const addScheduleToLookup = (schedule) => ({
  type: ADD_SCHEDULE_TO_LOOKUP,
  payload: { schedule }
});

export const DELETE_SCHEDULE_PENDING = 'DELETE_SCHEDULE_PENDING';
export const deleteSchedulePending = (schedule) => ({
  type: DELETE_SCHEDULE_PENDING,
  payload: { schedule }
});

export const DELETE_SCHEDULE_CONFIRMED = 'DELETE_SCHEDULE_CONFIRMED';
export const deleteScheduleConfirmed = (schedule) => ({
  type: DELETE_SCHEDULE_CONFIRMED,
  payload: { schedule }
});

export const CONFIRM_SCHEDULE = 'CONFIRM_SCHEDULE';
export const confirmScheduleLocal = (schedule) => ({
  type: CONFIRM_SCHEDULE,
  payload: { schedule }
});

export const EDIT_SCHEDULE = 'EDIT_SCHEDULE';
export const editScheduleLocal = (schedule) => ({
  type: EDIT_SCHEDULE,
  payload: { schedule }
});