import {
  UPDATE_MY_ARCHIVES, UPDATE_MY_PROFILE,
  UPDATE_MY_TOPICS, UPDATE_ONBOARDING_COMPLETE
} from './actions';


const initialState = {myTopics:[], myArchives:[]};
export const profile = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {

    case UPDATE_MY_ARCHIVES: {
      const {conversations: conversations} = payload;
      return {...state,
        myArchives: conversations
      }
    }

    case UPDATE_MY_TOPICS: {
      const {topics: topics} = payload;
      return {...state,
        myTopics: topics
      }
    }

    default:
      return state;
  }
}