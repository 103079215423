export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const openLoginModal = () => ({
  type: OPEN_LOGIN_MODAL,
});

export const UPDATE_USER_ME = 'UPDATE_USER_ME';
export const updateMyProfile = (user) => ({
  type: UPDATE_USER_ME,
  payload: { user },
});

export const UPDATE_USER_ME_PAYMENT = 'UPDATE_USER_ME_PAYMENT';
export const updateMyPaymentInfo = (stripeId, isStripeSetup) => ({
  type: UPDATE_USER_ME_PAYMENT,
  payload: { stripeId, isStripeSetup },
});

export const OPEN_SIGN_UP_MODAL = 'OPEN_SIGN_UP_MODAL';
export const openSignUpModal = () => ({
  type: OPEN_SIGN_UP_MODAL,
});

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const LOGIN_UPDATE_MODAL_STAGE = 'LOGIN_UPDATE_MODAL_STAGE';
export const loginUpdateModalStage = (stage) => ({
  type: LOGIN_UPDATE_MODAL_STAGE,
  payload: { stage },
});

export const REGISTER_USER_PHONE = 'REGISTER_USER_PHONE';
export const registerUserPhone = () => ({
  type: REGISTER_USER_PHONE,
});

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const markUserLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  payload: { user },
});

export const UPDATE_LOGIN_DETAILS = 'UPDATE_LOGIN_DETAILS';
export const updateLoginDetails = (loginDetails) => ({
  type: UPDATE_LOGIN_DETAILS,
  payload: { loginDetails },
});
