import { RDUser } from '../models/RDUser';
import { getCurrentPath } from '../navigation/selectors';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';

export const getInviteCodeFieldText = (state) => {
  if (
    state.form.onboarding != null
    && state.form.onboarding.values != null
    && state.form.onboarding.values.invite_code != null
  ) {
    return state.form.onboarding.values.invite_code;
  }
  return '';
};

export const getIsVerifyingHandler = (state) => state.login.isVerifyingHandler;

export const getLoggedInUser = (state) => {
  if (state.login != null && state.login.user != null) {
    return new RDUser(state.login.user);
  }
  return null;
};

export const getIsOnboardingModalOpen = (state) => {
  const user = getLoggedInUser(state);
  const path = getCurrentPath(state);

  const isPathForOpenConvo = RDRouteDirectory.isPathForOpenConversation(path);
  if (user !== null && !user.isOnboardingComplete && !isPathForOpenConvo.isRouteOpenConversation) {
    return true;
  }
  return false;
};

export const isUserLoggedIn = (state) => {
  if (state.login != null && state.login.user != null) {
    return true;
  }
  return false;
};

export const getLoggedInUserRaw = (state) => {
  if (state.login != null && state.login.user != null) {
    return state.login.user;
  }
  return null;
};

export const getIsHandlerVerified = (state) => state.login.isHandlerVerified;

export const getHasHandlerBeenChecked = (state) => state.login.hasHandlerBeenChecked;

export const getLoginModalStage = (state) => state.login.loginModalStage;

export const getLoginDetailsEmail = (state) => {
  if (state.login.loginDetails && state.login.loginDetails.email) {
    return state.login.loginDetails.email;
  }
  return null;
};

export const getLoginDetailsPhoneInfo = (state) => {
  if (state.login.loginDetails && state.login.loginDetails.phoneNumber) {
    return [state.login.loginDetails.phoneNumber, state.login.loginDetails.countryCallCode];
  }
  return [null, null];
};

export const getLoginDetailsPathway = (state) => {
  if (state.login.loginDetails && state.login.loginDetails.pathway) {
    return state.login.loginDetails.pathway;
  }
  return null;
};

export const getLastLoginTimestamp = (state) => {
  if (state.login) {
    return state.login.lastLogin;
  }
  return null;
};

export const getLoginDetailsLoginPathway = (state) => {
  if (state.login.loginDetails && state.login.loginDetails.loginPathway) {
    return state.login.loginDetails.loginPathway;
  }
  return null;
};

export const getPhoneNumber = (state) => {
  if (
    state.form != null
    && state.form.onboarding != null
    && state.form.onboarding.values != null
    && state.form.onboarding.values.mobile_number != null
  ) {
    return '';
  }
  return '';
};
