import { RDUser } from './RDUser';
import { RDTextUtils } from '../utilities/RDTextUtils';

export class RDComment {
  constructor(json) {
    if (!json) {
      this.commentId = RDTextUtils.uuidv4();
      this.dateCreated = Math.floor(Date.now() / 1000);
      return;
    }

    this.commentId = json.id;
    this.conversationId = json.conversation_id;
    this.user = new RDUser(json.user);
    this.text = json.text;
    this.dateCreated = json.date_created;
    this.dateUpdated = json.date_updated;
    this.timestamp = json.timestamp;
  }
}
