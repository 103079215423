import { RDTopic } from '../models/RDTopic';
import { getCurrentPath, getCurrentQuery } from '../navigation/selectors';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';
import { RDUser } from '../models/RDUser';
import { store } from '../store';
import { fetchTopicForId, fetchUserForUserId } from './thunks';

export const getIsLoadingTopics = (state) => state.talk.isLoadingTopics;

export const getLatestTopics = (state) => {
  const processedTopics = state.talk.latestTopics.map((rawTopic) => new RDTopic(rawTopic));
  return processedTopics;
};

export const getFeaturedTopics = (state) => {
  const processedTopics = state.talk.featuredTopics.map((rawTopic) => new RDTopic(rawTopic));
  return processedTopics;
};

export const getLiveUserIds = (state) => {
  const { liveUserIds } = state.talk;
  return liveUserIds;
};

export const getLiveRequests = (state) => {
  const liveRequestsRaw = state.talk.liveRequests;
  const liveRequests = liveRequestsRaw.map((rawRequest) => (
    {
      requester: new RDUser(rawRequest.requester),
      topic: new RDTopic(rawRequest.topic),
      conversationUUID: rawRequest.conversationUUID,
    }));
  return liveRequests;
};

export const getTopicForId = async (topicId, state) => {
  const localTopic = getLocalTopicForTopicId(topicId, state);
  if (localTopic) {
    return localTopic;
  }
  const fetchedTopic = await store.dispatch(await fetchTopicForId(topicId));
  return fetchedTopic;
};

export const getUserForId = async (userId, state) => {
  const localUser = getLocalUserForId(userId, state);
  if (localUser) {
    // console.log("LOCAL USER")
    // console.log(localUser)
    return localUser;
  }
  const fetchedUser = await store.dispatch(await fetchUserForUserId(userId));
  // console.log("FETCHED USER")
  // console.log(fetchedUser)
  return fetchedUser;
};

export const getLocalUserForId = (userId, state) => {
  if (state.talk.userIdLookup[userId]) {
    return new RDUser(state.talk.userIdLookup[userId]);
  }
  return null;
};

export const getLocalTopicForTopicId = (topicId, state) => {
  let topic = null;
  console.log('getLocalTopicForTopicId');
  console.log(topicId);
  console.log(state.talk.topicIdLookup[topicId]);

  if (state.talk.topicIdLookup[topicId]) {
    topic = new RDTopic(state.talk.topicIdLookup[topicId]);
  } else {
    console.log("Can't find topic locally.");
  }
  return topic;
};

export const getLocalTopicForTopicUUID = (topicUUID, state) => {
  let topic = null;
  if (state.talk.topicLookup[topicUUID]) {
    topic = new RDTopic(state.talk.topicLookup[topicUUID]);
  } else {
    console.log("Can't find topic locally.");
  }
  return topic;
};

export const getUserForUsername = (username, state) => {
  let user = null;
  if (state.talk.userLookup[username]) {
    user = new RDUser(state.talk.userLookup[username]);
  } else {
    console.log("Can't find user locally.");
  }
  return user;
};

export const getSelectedTopic = (state) => {
  if (state.conversation.notificationTopic != null) {
    return new RDTopic(state.conversation.notificationTopic);
  }

  // Retrieve topic from URL
  const currentPath = getCurrentPath(state);
  const currentQuery = getCurrentQuery(state);

  const topicPathCheck = RDRouteDirectory.isPathForTopic(currentPath);
  const conversationPathCheck = RDRouteDirectory.isPathForConversation(currentPath, currentQuery);
  const newSchedulePathCheck = RDRouteDirectory.isPathForNewSchedule(currentPath, currentQuery);

  if (topicPathCheck.isRouteTopic) {
    const topic = getLocalTopicForTopicUUID(topicPathCheck.topicId, state);
    return topic;
  } if (conversationPathCheck.isRouteConversation) {
    const topic = getLocalTopicForTopicUUID(conversationPathCheck.topicId, state);
    return topic;
  } if (newSchedulePathCheck.isRouteNewSchedule) {
    const topic = getLocalTopicForTopicUUID(newSchedulePathCheck.topicId, state);
    return topic;
  }
  return state.talk.selectedTopic;
};

export const getIsCreateTopicModalOpen = (state) => getCurrentPath(state) === RDRouteDirectory.createTopic;

export const getIsConversationRequestModalOpen = (state) => getCurrentPath(state) === RDRouteDirectory.requestConversation;

export const getIsTopicModalOpen = (state) => {
  const currentPath = getCurrentPath(state);
  const topicPathCheck = RDRouteDirectory.isPathForTopic(currentPath);
  if (topicPathCheck.isRouteTopic) {
    return true;
  }
  return false;
};

export const getNewTopicCurrentText = (state) => {
  if (state.form != null && state.form.new_topic != null && state.form.new_topic.values != null && state.form.new_topic.values.topic != null) {
    return state.form.new_topic.values.topic;
  }
  return '';
};
