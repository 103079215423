import React, {
  useEffect, memo, useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { RDColors } from '../utilities/RDColors';
import { RDIconURIs } from '../utilities/RDIconURIs';
import RDLogo from '../global/RDLogo';
import { logOutOfRadical, userEnteredApp } from './thunks';
import { getCurrentPath } from './selectors';
import { getLoggedInUser } from '../onboarding/selectors';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';
import { savePreSearchRoute, toggleWebPushModal } from './actions';
import { registerWebPushSubscriptionForUser } from '../talk/thunks';
import { RDConfig } from '../config';
import { getAgendaNewItemsCount } from '../schedule/selectors';

export const NavigationSection = {
  TALK: 'TALK',
  LISTEN: 'LISTEN',
  REVIEW: 'REVIEW',
  DISPATCH: 'DISPATCH',
  PROFILE: 'PROFILE',
};

// box-shadow: none|h-offset v-offset blur spread color;

const Container = styled.div`
  width: 280px;
  height: 100vh;
  overflow: hidden;
  border-radius: inherit;
  float: right;
  display:inline-block;
  box-shadow: 0px 1px 9px 0px ${RDColors.hexColorWithOpacity('#000000', 0.075)};
  background-color: white;
  
  @media only screen and (max-width: 925px) {
      width: 80px;
  }`;

const RadicalLogoContainer = styled.div`
  width: 100%;
  height: 64px;
  padding-left: 10px;
  padding-top: 8px;
  margin-top: 16px;
  background-color: transparent;
  @media only screen and (max-width: 925px) {
    padding-left: 0px;
  }
  `;

const RadicalTitle = styled.div`
  font-family: "ModerneSans";
  letter-spacing: 7px;
  text-align: left;
  font-size: 26px;
  display: inline-block;
  margin-left:14px;
  line-height: 64px;
  float:left;
  @media only screen and (max-width: 925px) {
      visibility: hidden;
      height: 0px;
      width: 0px;
  }
`;

const NavOptionsHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  @media only screen and (max-width: 925px) {
    padding-left: 3px;
  }
  padding-top:10px;
`;

const NavOption = styled.div`
  font-family: "Avenir";
  font-size: 26px;
  font-weight: 500;
  align-content: center;
  align-items: center;
  align-self: flex-start;
  height: 50px;
  border-radius: 29px;
  display: flex;
  margin-top: 10px;
  line-height: 50px;
  background-color: transparent;
  padding-left:16px;
  padding-right: 24px;
  
  color: ${(props) => (props.isSelected ? RDColors.purpleBlue : 'black')};
  opacity: ${(props) => (props.isSelected ? 1.0 : 0.65)};
  fill: ${RDColors.purpleBlue};

  font-weight: ${(props) => (props.isSelected ? 500 : 400)};

  &:hover {
    background-color: ${RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.15)};
    color: ${RDColors.hexColorWithOpacity(RDColors.purpleBlue, 1.0)} !important;
    font-weight:500 !important;
    opacity: 1.0;
  }
   
  @media only screen and (max-width: 925px) {
    padding-left:10px;
    padding-right: 10px;
    margin-left: 5px;
    flex-direction:column;
    width: 42px;
    height: auto;
  }
  
`;
// https://codepen.io/sosuke/pen/Pjoqqp

const NavTitle = styled.div`
  display: inline-block;
  font-family: "AvenirLight";
  font-size: 25px;
  font-weight: 300;
  float: left;
  margin-left: 8px;
  user-select: none;
  
  @media only screen and (max-width: 925px) {
      font-size: 11px;
      margin-left: 0px;
      height: 12px;
      line-height:12px;
    //visibility: hidden;
      //height: 0px;
      //width: 0px;
  }
`;

const NavImage = styled.svg`
  float: left;
  display: inline-block;
  background-repeat: no-repeat;
  height: 42px;
  width: 42px;
  background-position: center;
  background-size: contain;
`;

const UserInfoView = styled.div`
  width: 80%;
  height: 60px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 925px) {
    visibility: hidden;
    height: 0px;
    width: 0px;
    margin-top: 0px;
  }
`;

const LogOutButton = styled.button`
  width: 80px;
  cursor: pointer;
  height: 20px;
  margin-top: 10px;
  margin-left: 13px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  font-family: "Avenir";
  @media only screen and (max-width: 925px) {
    margin-left:0px;
  }
`;

const LogInButton = styled.button`
  width: 80px;
  cursor: pointer;
  height: 20px;
  margin-top: 24px;
  margin-left: 8px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  font-family: "Avenir";
  
  @media only screen and (max-width: 925px) {
    margin-left:0px;
  }
`;

const ProfileImage = styled.img`
  width: 40px;
  margin-top: 6px;
  height: 40px;
  border-radius: 20px;
  background-color: gray;
  display: inline-block;
  float: left;
  object-fit: cover;
`;

const UserInfo = styled.div`
  display: inline-block;
  float: left;
  margin-top: 4px;
  margin-left: 10px;
`;

const NameLabel = styled.div`
  display: block;
  float: left;
  height: 20px;
  font-family: "Avenir";
  font-size: 16px;
`;

const UsernameLabel = styled.div`
  height: 20px;
  margin-top: 23px;
  font-family: "Avenir";
  font-size: 15px;
  font-weight: 500;
`;

const NewsButton = styled.div`
  font-family: "AvenirBold", sans-serif;
  font-size: 16px;
  color:  ${RDColors.hexColorWithOpacity(RDColors.blue, 0.85)};;
  display: block;
  border-radius: 8px;
  margin-left: 28px;
  cursor: pointer;
  @media only screen and (max-width: 925px) {
      margin-left: 0px;
      text-align: center;
  }
`;

const NavImageTalk = styled(NavImage)`
  background-image: ${(props) => (props.isSelected ? `url("${RDIconURIs.talkSelect}")` : `url("${RDIconURIs.talk}")`)};
`;

const NavImageListen = styled(NavImage)`
  background-image: ${(props) => (props.isSelected ? `url("${RDIconURIs.listenSelect}")` : `url("${RDIconURIs.listen}")`)};
`;

const NavImageReview = styled(NavImage)`
  background-image: ${(props) => (props.isSelected ? `url("${RDIconURIs.reviewSelect}")` : `url("${RDIconURIs.review}")`)};
`;

const NavImageDispatch = styled(NavImage)`
  background-image: ${(props) => (props.isSelected ? `url("${RDIconURIs.bellSelect}")` : `url("${RDIconURIs.panelBell}")`)};
`;

const NavImageProfile = styled(NavImage)`
  background-image: ${(props) => (props.isSelected ? `url("${RDIconURIs.profileSelect}")` : `url("${RDIconURIs.profile}")`)};
`;

const SearchView = styled.div`
  height: 26px;
  padding: 4px;
  border-radius: 20px;
  padding: 6px;
  width: 220px;
  cursor: pointer;
  border-width:1px;
  border-style:solid;
  display: flex;
  align-items: center;
  background-color: ${RDColors.eugeneGray};
  border-color: ${RDColors.eugeneGray};
  margin-left: auto;
  margin-right: auto;
  font-family: Avenir;
  margin-top:8px;
  margin-bottom:-4px;
  font-size: 15px;
  
  @media only screen and (max-width: 925px) {
    width: 26px;
    padding: 6px;
  }
`;

const SearchImage = styled.img`
  width: 27px;
  height: 27px;
  padding-left: 4px;
  filter: invert(85%) sepia(0%) saturate(634%) hue-rotate(171deg) brightness(84%) contrast(97%);
  
  @media only screen and (max-width: 925px) {
    padding-left: 0px;
  }
`;

const NewItemsAgenda = styled.div`
  background-color: ${RDColors.pink};
  border-radius: 10px;
  padding: 4px;
  padding-top: 5px;
  padding-left:6px;
  padding-right:6px;
  margin-bottom: 32px;
  margin-left: 1px;
  font-family: Avenir;
  font-size: 13px;
  font-weight: 500;
  color:white;
  display: inline-block;
  flex-grow: 0;
  height: 16px;
  line-height: 16px;
  text-align: center;
  filter: none !important;
  left:  214px;
  top: 270px;
  position: absolute;

  @media only screen and (max-width: 925px) {
    left:  50px !important;
    margin-top: 18px !important;
  }
`;

const SearchTextView = styled.input`
  height: 100%;
  width: 170px;
  display: inline-block;
  cursor: pointer;
  pointer-events: none;
  color: ${RDColors.sectionTitleGray};
  font-family: Avenir;
  font-size: 17px;
  border: none;
  padding-left: 6px;
  background-color: transparent;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b5b5b5;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #b5b5b5;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #b5b5b5;
  }
  
  @media only screen and (max-width: 925px) {
    opacity: 0;
  }
`;

const logoStyle = {
  marginTop: 3,
  marginLeft: 16,
};

const displayProfileImage = (profileImageUrl) => {
  let imageUrl = RDIconURIs.defaultProfileImage;
  if (profileImageUrl !== null) {
    imageUrl = profileImageUrl;
  }

  return <ProfileImage width={40} height={40} src={imageUrl} />;
};

const RDLogoStyled = styled(RDLogo)``;

const checkPushStatus = async (togglePushModal, registerUserForWebPush, currentUser) => {
  if (typeof (navigator) === 'undefined' || typeof (navigator.serviceWorker) === 'undefined') {
    return;
  }
  const sw = await navigator.serviceWorker.ready;
  if (sw.pushManager && (Notification.permission === 'default' || Notification.permission === 'denied')) {
    // console.log("PUSH: Display permission modal")
    togglePushModal(true);
  } else if (sw.pushManager && Notification.permission === 'granted') {
    // console.log("PUSH: Update push subscription for user")

    setTimeout(async () => {
      const sw = await navigator.serviceWorker.ready;
      const push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: RDConfig.webPushServerKey,
      });
      const subscription = JSON.stringify(push);
      const baseUrl = window.location.origin;
      const { userAgent } = navigator;
      registerUserForWebPush(subscription, currentUser, baseUrl, userAgent);
    }, 6500);
  } else {
    console.log('PUSH: Environment does not support web push notifications.');
  }
};

// eslint-disable-next-line react/display-name
const NavigationView = memo(({
  agendaNewItemsCount,
  pathname,
  currentUser,
  changeSection,
  logOut,
  togglePushModal,
  registerUserForWebPush,
  enteredApp,
}) => {
  const dispatch = useDispatch();

  const [isTalkActive, setIsTalkActive] = useState(false);
  const [isListenActive, setIsListenActive] = useState(false);
  const [isReviewActive, setIsReviewActive] = useState(false);
  const [isNotificationsActive, setIsNotificationsActive] = useState(false);
  const [isProfileActive, setIsProfileActive] = useState(false);

  useEffect(() => {
    checkPushStatus(togglePushModal, registerUserForWebPush, currentUser);
    enteredApp(currentUser);
  }, []);
  const navView = (
    <Container>
      <RadicalLogoContainer>
        <RDLogoStyled width={48} height={48} isAnimated={false} style={logoStyle} />
        <RadicalTitle>RADICAL</RadicalTitle>
      </RadicalLogoContainer>

      <SearchView onClick={() => {
        console.log('Clicked search');
        dispatch(savePreSearchRoute(pathname));
        changeSection(RDRouteDirectory.search);
      }}
      >
        <SearchImage disabled src={RDIconURIs.search} />
        <SearchTextView disabled type="text" placeholder="Search" />
      </SearchView>
      <NavOptionsHolder>

        <NavOption
          isSelected={pathname === RDRouteDirectory.talk || pathname === RDRouteDirectory.index}
          onClick={() => {
            changeSection(RDRouteDirectory.talk);
          }}
          onMouseEnter={() => {
            setIsTalkActive(true);
          }}
          onMouseLeave={() => {
            setIsTalkActive(false);
          }}
        >
          <NavImageTalk
            isSelected={
              pathname === RDRouteDirectory.talk
              || pathname === RDRouteDirectory.index
              || isTalkActive
            }
          />
          <NavTitle>Talk</NavTitle>
        </NavOption>

        {/* <NavOption */}
        {/*  isSelected={ */}
        {/*    pathname === RDRouteDirectory.listen */}
        {/*    || RDRouteDirectory.isPathForArchivedConversation(pathname).isRouteArchivedConversation */}
        {/*  } */}
        {/*  onClick={() => { */}
        {/*    changeSection(RDRouteDirectory.listen); */}
        {/*  }} */}
        {/*  onMouseEnter={() => { */}
        {/*    setIsListenActive(true); */}
        {/*  }} */}
        {/*  onMouseLeave={() => { */}
        {/*    setIsListenActive(false); */}
        {/*  }} */}
        {/* > */}
        {/*  <NavImageListen */}
        {/*    isSelected={ */}
        {/*      pathname === RDRouteDirectory.listen */}
        {/*      || RDRouteDirectory.isPathForArchivedConversation( */}
        {/*        pathname, */}
        {/*      ).isRouteArchivedConversation */}
        {/*      || isListenActive */}
        {/*    } */}
        {/*  /> */}
        {/*  <NavTitle>Listen</NavTitle> */}
        {/* </NavOption> */}

        <NavOption
          isSelected={pathname === RDRouteDirectory.review}
          onClick={() => {
            changeSection(RDRouteDirectory.review);
          }}
          onMouseEnter={() => {
            setIsReviewActive(true);
          }}
          onMouseLeave={() => {
            setIsReviewActive(false);
          }}
        >
          <NavImageReview isSelected={pathname === RDRouteDirectory.review || isReviewActive} />
          <NavTitle>Review</NavTitle>
        </NavOption>

        <NavOption
          isSelected={
            pathname === RDRouteDirectory.dispatch
            || RDRouteDirectory.isPathForSchedule(pathname).isRouteSchedule
          }
          onClick={() => {
            changeSection(RDRouteDirectory.dispatch);
          }}
          onMouseEnter={() => {
            setIsNotificationsActive(true);
          }}
          onMouseLeave={() => {
            setIsNotificationsActive(false);
          }}
        >
          <NavImageDispatch isSelected={
                                pathname === RDRouteDirectory.dispatch
                                || RDRouteDirectory.isPathForSchedule(pathname).isRouteSchedule
                                || isNotificationsActive
                              }
          />
          <NavTitle>Notifications</NavTitle>
        </NavOption>
        {(agendaNewItemsCount > 0)
          && <NewItemsAgenda>{agendaNewItemsCount}</NewItemsAgenda>}

        <NavOption
          isSelected={
            (pathname === RDRouteDirectory.profile
              || pathname === RDRouteDirectory.editProfile
              || pathname === RDRouteDirectory.interests
            )
          }
          onClick={() => {
            changeSection(RDRouteDirectory.profile);
          }}
          onMouseEnter={() => {
            setIsProfileActive(true);
          }}
          onMouseLeave={() => {
            setIsProfileActive(false);
          }}
        >
          <NavImageProfile isSelected={
            (pathname === RDRouteDirectory.profile
              || pathname === RDRouteDirectory.editProfile
              || pathname === RDRouteDirectory.interests)
            || isProfileActive
          }
          />
          <NavTitle>Profile</NavTitle>
        </NavOption>
      </NavOptionsHolder>

      {currentUser !== null
          && (
          <UserInfoView>
            {displayProfileImage(currentUser.profileImageUrl)}
            <UserInfo>
              <NameLabel>
                {currentUser.getDisplayName()}
              </NameLabel>
              <UsernameLabel>
                @
                {currentUser.username}
              </UsernameLabel>
            </UserInfo>
          </UserInfoView>
          )}

      <NewsButton onClick={() => {
        window.open('https://radicalnews.substack.com', '_blank').focus();
      }}
      >
        Latest News
      </NewsButton>
      {currentUser !== null
          && <LogOutButton onClick={logOut}>Log Out</LogOutButton>}
      {currentUser === null
          && (
          <LogInButton onClick={() => {
            changeSection('/');
          }}
          >
            Log In
          </LogInButton>
          )}

    </Container>
  );

  return navView;
});

NavigationView.propTypes = {
  agendaNewItemsCount: PropTypes.number,
  pathname: PropTypes.string,
  currentUser: PropTypes.object,
  changeSection: PropTypes.func,
  logOut: PropTypes.func,
  togglePushModal: PropTypes.func,
  registerUserForWebPush: PropTypes.func,
  enteredApp: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentUser: getLoggedInUser(state),
  pathname: getCurrentPath(state),
  agendaNewItemsCount: getAgendaNewItemsCount(state),
});

const mapDispatchToProps = (dispatch) => ({
  changeSection: (sectionUrl) => dispatch(push(sectionUrl)),
  logOut: () => dispatch(logOutOfRadical()),
  enteredApp: (user) => dispatch(userEnteredApp(user)),
  togglePushModal: (visible) => dispatch(toggleWebPushModal(visible)),
  registerUserForWebPush: (
    sub,
    user,
    baseUrl,
    userAgent,
  ) => dispatch(registerWebPushSubscriptionForUser(sub, user, baseUrl, userAgent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationView);
