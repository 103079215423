
const initialState = { isSearchViewOpen: false};

export const search = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {


    default:
      return state;
  }
}