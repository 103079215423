export const RDColors = {
  pink: '#FF3092',
  darkPink: '#cc136f',
  blue: '#5088e5',
  purple: '#9C5AEE',
  purpleBlue: '#7671E9',
  darkPurpleBlue: '#524ce4',
  liveGreen: '#48e298',
  liveGreenLight: '#79fcbd',
  liveRed: '#e81414',
  iconSelect: '#d61a72',
  eugeneGray: '#e6e6e6',
  sectionTitleGray: '#4a4a4a',
  sectionBackgroundGray: '#f7f7f7',
  separatorGray: '#d8d7d7',
  deleteRed: '#bf1c0d',
  warningOrange: '#db621d',
  activeBlue: '#0d3ea1',

  excellentGreen: '#49bd0f',
  acceptableYellowGreen: '#97bd0f',
  acceptableYellow: '#ccbd16',
  poorRed: '#b30e0e',

  hexColorWithOpacity: function(hex, opacity) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    let red = parseInt(result[1], 16)
    let green = parseInt(result[2], 16)
    let blue = parseInt(result[3], 16)

    return `rgba(${red}, ${green}, ${blue}, ${opacity})`
  },

  /*
    blend two colors to create the color that is at the percentage away from the first color
    this is a 5 step process
        1: validate input
        2: convert input to 6 char hex
        3: convert hex to rgb
        4: take the percentage to create a ratio between the two colors
        5: convert blend to hex
    @param: color1      => the first color, hex (ie: #000000)
    @param: color2      => the second color, hex (ie: #ffffff)
    @param: percentage  => the distance from the first color, as a decimal between 0 and 1 (ie: 0.5)
    @returns: string    => the third color, hex, represenatation of the blend between color1 and color2 at the given percentage
*/
  blendColors: function(color1, color2, percentage){
    // check input
    color1 = color1 || '#000000';
    color2 = color2 || '#ffffff';
    percentage = percentage || 0.0;

    // 1: validate input, make sure we have provided a valid hex
    if (color1.length != 4 && color1.length != 7)
      throw new Error('colors must be provided as hexes');

    if (color2.length != 4 && color2.length != 7)
      throw new Error('colors must be provided as hexes');

    if (percentage > 1 || percentage < 0)
      throw new Error('percentage must be between 0 and 1');

    // 2: check to see if we need to convert 3 char hex to 6 char hex, else slice off hash
    //      the three character hex is just a representation of the 6 hex where each character is repeated
    //      ie: #060 => #006600 (green)
    if (color1.length == 4)
      color1 = color1[1] + color1[1] + color1[2] + color1[2] + color1[3] + color1[3];
    else
      color1 = color1.substring(1);
    if (color2.length == 4)
      color2 = color2[1] + color2[1] + color2[2] + color2[2] + color2[3] + color2[3];
    else
      color2 = color2.substring(1);

    // 3: we have valid input, convert colors to rgb
    color1 = [parseInt(color1[0] + color1[1], 16), parseInt(color1[2] + color1[3], 16), parseInt(color1[4] + color1[5], 16)];
    color2 = [parseInt(color2[0] + color2[1], 16), parseInt(color2[2] + color2[3], 16), parseInt(color2[4] + color2[5], 16)];

    // 4: blend
    let color3 = [
      (1 - percentage) * color1[0] + percentage * color2[0],
      (1 - percentage) * color1[1] + percentage * color2[1],
      (1 - percentage) * color1[2] + percentage * color2[2]
    ];

    // 5: convert to hex
    color3 = '#' + this.int_to_hex(color3[0]) + this.int_to_hex(color3[1]) + this.int_to_hex(color3[2]);

    // return hex
    return color3;

  },

/*
    convert a Number to a two character hex string
    must round, or we will end up with more digits than expected (2)
    note: can also result in single digit, which will need to be padded with a 0 to the left
    @param: num         => the number to conver to hex
    @returns: string    => the hex representation of the provided number
*/
  int_to_hex: function(num) {
    let hex = Math.round(num).toString(16);
    if (hex.length == 1)
      hex = '0' + hex;
    return hex;
  }

}