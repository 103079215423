export const CHANGE_NAV_SECTION = 'CHANGE_NAV_SECTION';
export const changeNavSection = (section) => ({
  type: CHANGE_NAV_SECTION,
  payload: { section },
});

export const LOG_OUT = 'LOG_OUT';
export const logOut = () => ({
  type: LOG_OUT,
});

export const SAVE_PRE_SEARCH_ROUTE = 'SAVE_PRE)SEARCH_ROUTE';
export const savePreSearchRoute = (route) => ({
  type: SAVE_PRE_SEARCH_ROUTE,
  payload: { route },
});

export const TOGGLE_WEB_PUSH_MODAL = 'TOGGLE_WEB_PUSH_MODAL';
export const toggleWebPushModal = (visible) => ({
  type: TOGGLE_WEB_PUSH_MODAL,
  payload: { visible },
});
