// eslint-disable-next-line @typescript-eslint/no-var-requires
const sma = require('sma');

const RDPlayerInstance = new Audio();
export const RDPlayer = RDPlayerInstance;

export const loadSource = function (src) {
  RDPlayer.src = src;
  RDPlayer.load();
  // console.log("========================")
  // console.log("Loading src for playback")
  // console.log(src)
};

export const getPlayerCurrentTime = function () {
  return RDPlayer.currentTime;
};

export const seekAndPlay = function (ms) {
  RDPlayer.currentTime = ms;
  RDPlayer.play();
};

export const stopPlayer = function () {
  RDPlayer.pause();
  RDPlayer.ontimeupdate = null;
  RDPlayer.oncanplay = null;
  RDPlayer.onended = null;
  RDPlayer.src = null;
};

export const filterLocalChannelData = (channelData, samples) => {
  if (channelData.length < samples) {
    samples = channelData.length;
  }

  const rawData = channelData;
  const blockSize = Math.floor(rawData.length / samples);
  let filteredData = [];
  for (let i = 0; i < samples; i += 1) {
    const blockStart = blockSize * i; // the location of the first sample in the block
    let sum = 0;
    for (let j = 0; j < blockSize; j += 1) {
      sum += Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
    }
    filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
  }

  filteredData = filteredData.filter((value) => !Number.isNaN(value));

  filteredData = sma(filteredData, 3);
  if (samples > 1200) {
    filteredData = sma(filteredData, 5);
  }

  const normalizationMultiplier = Math.max(...filteredData) ** -1;
  const normalizedData = filteredData.map((n) => n * normalizationMultiplier);

  return normalizedData;
};

export const getAudioData = async function (src) {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const res = await fetch(src);
  const arrayBuffer = await res.arrayBuffer();
  const audioData = await audioContext.decodeAudioData(arrayBuffer);
  return audioData;
};
