import { useEffect, useState } from 'react';
import { axiosInstance, isResponseSuccess, RDApi } from '../api/api';
import { RDConversation } from '../models/RDConversation';

const useConversationForUUID = (uuid:string, forceProd:boolean = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState<RDConversation|null>(null);

  const fetchConversationForUUID = async () => {
    setIsLoading(true);

    let endpoint = RDApi.fetchArchivedConversationForUUID;
    if (forceProd) {
      endpoint = `https://radical.overtonelabs.com/${RDApi.fetchArchivedConversationForUUID}`;
    }
    const res = await axiosInstance.get(endpoint, { params: { uuid } });
    if (isResponseSuccess(res)) {
      if (res.data.conversation === 'unavailable') {
        setIsLoading(false);
        setConversation(null);
        return;
      }
      const fetchedConversation = new RDConversation(res.data.conversation);
      setIsLoading(false);
      setConversation(fetchedConversation);
      return;
    }
    setIsLoading(false);
    setConversation(null);
  };

  useEffect(() => {
    if (uuid && uuid.length > 0) {
      console.log(`Fetching conversation for uuid: ${uuid}`);
      fetchConversationForUUID().catch(console.error);
    }
  }, [uuid]);
  return [isLoading, conversation] as const;
};

export default useConversationForUUID;
