import {RDUser} from './RDUser';
import {RDTopic} from './RDTopic';
import {store} from "../store";
import {getLoggedInUser} from "../onboarding/selectors";
import {RDConversation} from "./RDConversation";
import {RDComment} from "./RDComment";

export class RDNotification {
  constructor(json) {
    this.conversation = new RDConversation(json[`conversation`])
    this.from = new RDUser(json[`from`])
    this.dateCreated = json[`date_created`]
    this.type = json[`type`]
    if (json[`comment`]) {
      this.comment = new RDComment(json[`comment`])
    }
  }
}