export const TOGGLE_IS_LOADING_REVIEWS = 'TOGGLE_IS_LOADING_REVIEWS';
export const toggleIsLoadingReviews = (isLoading) => ({
  type: TOGGLE_IS_LOADING_REVIEWS,
  payload: {isLoading}
});

export const LOAD_REVIEWS_SUCCESS = 'LOAD_REVIEWS_SUCCESS'
export const loadReviewsSuccess = (reviews) => ({
  type: LOAD_REVIEWS_SUCCESS,
  payload: {reviews}
});

export const RATE_REVIEW = 'RATE_REVIEW'
export const rateReviewLocally = (review) => ({
  type: RATE_REVIEW,
  payload: {review}
});