export const TOGGLE_IS_LOADING_ARCHIVES = 'TOGGLE_IS_LOADING_ARCHIVES';
export const toggleIsLoadingArchives = (isLoading) => ({
  type: TOGGLE_IS_LOADING_ARCHIVES,
  payload: { isLoading },
});

export const LOAD_ARCHIVES_SUCCESS = 'LOAD_ARCHIVES_SUCCESS';
export const loadArchivesSuccess = (archives) => ({
  type: LOAD_ARCHIVES_SUCCESS,
  payload: { archives },
});

export const ADD_ARCHIVE_TO_LOOKUP = 'ADD_ARCHIVE_TO_LOOKUP';
export const addArchiveToLookup = (archive) => ({
  type: ADD_ARCHIVE_TO_LOOKUP,
  payload: { archive },
});

export const TOGGLE_EMBED_MODAL = 'TOGGLE_EMBED_MODAL';
export const toggleEmbedModal = (isOpen) => ({
  type: TOGGLE_EMBED_MODAL,
  payload: { isOpen },
});
