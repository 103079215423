import {LOAD_MATCHES_SUCCESS, TOGGLE_IS_LOADING_MATCHES} from "./actions";

const initialState = {matches: [], isFetchingMatches: false};
export const home = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {

    case LOAD_MATCHES_SUCCESS: {
      const {matches: matches} = payload;
      return {...state,
        isFetchingMatches: false,
        matches: matches,
      }
    }

    case TOGGLE_IS_LOADING_MATCHES: {
      const {isLoading: isLoading} = payload;
      return {
        ...state,
        isFetchingMatches: isLoading
      }
    }

    default:
      return {
        ...state
      }
  }
}