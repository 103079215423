import { RDUser } from './RDUser';
import { RDTopic } from './RDTopic';
import { store } from '../store';
import { getCurrentConversation } from '../conversation/selectors';

export class RDConversation {
  constructor(json) {
    this.conversationId = json.id;
    this.creator = new RDUser(json.creator);
    this.partner = new RDUser(json.partner);
    this.topic = new RDTopic(json.position);
    this.dateStarted = json.date_started;
    this.dateFinished = json.date_finished;
    this.dualStereoAudioLink = json.dual_stereo_audio_link;
    this.dualAudioLink = json.dual_stereo_audio_link;
    this.creatorAudioLink = json.user1_audio_link;
    this.partnerAudioLink = json.user2_audio_link;
    this.durationMs = json.duration_ms;
    this.uuid = json.uuid;
    this.audioVisualDataUrl = json.conversation_eq_data_1sec;
    this.insightsDataUrl = json.conversation_insights_data;
    this.rawJson = json;
    this.isLiked = false;
    this.noLikes = json.no_likes;
    this.noComments = json.no_comments;
    this.metaImageUrl = json.meta_image_url;
    this.opentokSessionId = json.opentok_session_id;

    this.title = null;
    this.description = null;
    this.hifiAudioUrl = null;
    this.isHifiAudioAvailable = false;
    this.fetchedArchiveAssets = false;

    this.isActive = true;
    if (json.creator_inactivated) {
      const createdInactivated = json.creator_inactivated;
      if (this.creator.isMe() && createdInactivated) {
        this.creatorDeactivated = true;
        this.isActive = false;
      } else {
        this.creatorDeactivated = false;
      }
    }

    if (json.partner_inactivated) {
      const partnerInactivated = json.partner_inactivated;
      if (this.partner.isMe() && partnerInactivated) {
        this.partnerDeactivated = true;
        this.isActive = false;
      } else {
        this.partnerDeactivated = false;
      }
    }

    if (json.local_active) {
      this.isActive = json.local_active;
    }

    if (json.local_delete) {
      this.isDeleted = json.local_delete;
    }

    if (json.is_liked) {
      this.isLiked = json.is_liked;
    }

    if (json.title && json.title.length > 0) {
      this.title = json.title;
    }

    if (json.description && json.description.length > 0) {
      this.description = json.description;
    }

    if (json.premium_audio_link && json.premium_audio_link.length > 0) {
      this.hifiAudioUrl = json.premium_audio_link;
      this.isHifiAudioAvailable = true;
    }
  }

  getAudioLink() {
    return this.hifiAudioUrl !== null ? this.hifiAudioUrl : this.dualStereoAudioLink;
  }

  getDisplayTitle() {
    const displayTitle = this.title ? this.title : this.topic.title;
    return displayTitle;
  }

  getPartner() {
    if (this.creator.isMe()) {
      return this.partner;
    }

    if (this.partner.isMe()) {
      return this.creator;
    }

    return null;
  }

  static getCurrentConversation() {
    const state = store.getState();
    const currentConversation = getCurrentConversation(state);
    return currentConversation;
  }
}
