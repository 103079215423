import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { login } from './onboarding/reducers';
import { navigation } from './navigation/reducers';
import { talk } from './talk/reducers';
import { schedule } from './schedule/reducers';
import { profile } from './profile/reducers';
import { conversation } from './conversation/reducers';
import { review } from './review/reducers';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import {listen} from "./listen/reducers";
import {search} from "./search/reducers";
import {home} from "./home/reducers";

export const history = createBrowserHistory()
const reducers = {
    router: connectRouter(history),
    login: login,
    navigation: navigation,
    talk: talk,
    conversation: conversation,
    schedule:schedule,
    profile: profile,
    review: review,
    listen: listen,
    search: search,
    home: home,
    form: formReducer
};

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['router']
}

const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
    if (action.type === 'LOG_OUT') {
        console.log("STATE RESET!")
        state = undefined
    }
    return appReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer,
    composeWithDevTools(
        applyMiddleware(
            routerMiddleware(history),
            thunk
        )
    ));

console.log(store)
export const configureStore = () => store;