import {
  ADD_SCHEDULE_TO_LOOKUP, CONFIRM_SCHEDULE, DELETE_SCHEDULE_CONFIRMED, DELETE_SCHEDULE_PENDING, EDIT_SCHEDULE,
  LOAD_AGENDA_SUCCESS, RESTORE_SCHEDULE_TIMES,
  TOGGLE_IS_LOADING_AGENDA,
  TOGGLE_SCHEDULE_MODAL, UPDATE_AGENDA_NEW_ITEMS_COUNT, UPDATE_LAST_TIME_ON_NOTIFICATION_TAB
} from './actions';
import {ADD_TOPIC_TO_LOOKUP} from "../talk/actions";

const initialState = {lastTimeOnNotification:new Date(), agendaNewItems:0, scheduleLookup: {}, isScheduleModalOpen: false, pendingConversations: [], scheduledConversations:[], activityLog:[], isFetchingAgenda: false};

const removeScheduleFromArray  = (schedule, scheduleArray) => {
  let scheduleIndex = -1
  for (let i = 0; i < scheduleArray.length; i++ ) {
    let existingSchedule = scheduleArray[i]
    if (existingSchedule.uuid === schedule.uuid) {
      scheduleIndex = i;
    }
  }

  if (scheduleIndex !== -1) {
    scheduleArray.splice(scheduleIndex, 1)
  }
  return scheduleArray
}

const addScheduleToArray  = (schedule, scheduleArray) => {
  let raw = schedule.getRawJson()
  if (schedule.lastUpdatedUser) {
    raw['last_update_user'] = schedule.lastUpdatedUser.rawJson
  }

  if (schedule.waitingUser) {
    raw['waiting_user'] = schedule.waitingUser.rawJson
  }
  scheduleArray.push(raw)
  return scheduleArray
}


export const schedule = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case UPDATE_LAST_TIME_ON_NOTIFICATION_TAB: {

      let d = new Date();
      //d.setDate(d.getDate() - 2);

      return {...state,
        lastTimeOnNotification: d
      }
    }

    case ADD_SCHEDULE_TO_LOOKUP: {
      const {schedule: schedule} = payload;
      return {...state,
        scheduleLookup: {
          ...state.scheduleLookup,
          [schedule.uuid]: schedule
        }
      }
    }

    case UPDATE_AGENDA_NEW_ITEMS_COUNT: {
      let newItemsCount = 0
      for (let i=0; i<state.activityLog.length; i++) {
        let activity = state.activityLog[i]
        let activityDate = new Date(activity[`date_created`]*1000)

        //console.log(`Activity Date: `+activityDate)
        //console.log(`Last Time Date: `+ new Date(state.lastTimeOnNotification))

        if (activityDate > new Date(state.lastTimeOnNotification)) {
          newItemsCount += 1
        }
      }


      let conversations = []
      conversations = conversations.concat(state.pendingConversations)
      conversations = conversations.concat(state.scheduledConversations)
      for (let i=0; i<conversations.length; i++) {
        let schedule = conversations[i]
        let scheduleUpdateDate = new Date(schedule[`date_created`]*1000)
        console.log(`scheduleUpdateDate Date: `+scheduleUpdateDate)
        console.log(`Last Time Date: `+ new Date(state.lastTimeOnNotification))

        if (scheduleUpdateDate > new Date(state.lastTimeOnNotification)) {
          newItemsCount += 1
        }
      }

      return {...state,
        agendaNewItems: newItemsCount
      }
    }

    case CONFIRM_SCHEDULE: {
      const {schedule: schedule} = payload;
      let pendingConversations = removeScheduleFromArray(schedule, state.pendingConversations)
      let scheduledConversations = addScheduleToArray(schedule, state.scheduledConversations)
      console.log(scheduledConversations)
      return {...state,
        pendingConversations: pendingConversations,
        scheduledConversations: scheduledConversations
      }
    }

    case EDIT_SCHEDULE: {
      const {schedule: schedule} = payload;

      let pendingConversations = removeScheduleFromArray(schedule, state.pendingConversations)
      pendingConversations = addScheduleToArray(schedule, state.pendingConversations)

      console.log(pendingConversations)
      return {...state,
        pendingConversations: pendingConversations,
      }
    }

    case DELETE_SCHEDULE_PENDING: {
      const {schedule: schedule} = payload;
      let pendingConversations = removeScheduleFromArray(schedule, state.pendingConversations)
      return {...state,
        pendingConversations: pendingConversations
      }
    }

    case DELETE_SCHEDULE_CONFIRMED: {
      const {schedule: schedule} = payload;

      let scheduledConversations = removeScheduleFromArray(schedule, state.scheduledConversations)
      return {
        ...state,
        scheduledConversations: scheduledConversations
      }
    }

    case TOGGLE_IS_LOADING_AGENDA: {
      const {isLoading: isLoading} = payload;
      return {...state,
        isFetchingAgenda: isLoading
      }
    }

    case LOAD_AGENDA_SUCCESS: {
      const {agenda: agenda} = payload;
      // console.log("LOAD AGENDA SUCCESS")
      // console.log(agenda)

      let scheduleDictionary = {}
      let schedules = agenda[`pending`].concat(agenda[`confirmed`])
      for (let i = 0; i < schedules.length; i++) {
        let schedule = schedules[i]
        scheduleDictionary[schedule.uuid] = schedule
      }

      return {...state,
        isFetchingAgenda: false,
        pendingConversations: agenda[`pending`],
        scheduledConversations: agenda[`confirmed`],
        activityLog: agenda['activity'],
        scheduleLookup: scheduleDictionary
      }
    }

    default:
      return state;
  }
}