import {
  OPEN_CONVERSATION_MODAL,
  CLOSE_CONVERSATION_MODAL,
  UPDATE_CONVERSATION_STATUS,
  SET_CURRENT_CONVERSATION,
  TOGGLE_CONVERSATION_NOTIFICATION_MODAL,
  SHOW_CONVERSATION_NOTIFICATION_MODAL,
  CLEAR_CONVERSATION_NOTIFICATION_MODAL,
  CLEAR_CURRENT_CONVERSATION,
} from './actions';
import { RDConfig } from '../config';

export const RDConversationStatus = {
  waiting: 'waiting',
  partnerLeft: 'partnerLeft',
  active: 'active',
  unavailable: 'unavailable',
  full: 'full',
  readyOne: 'ready_one',
  readyAll: 'ready_all',
  started: 'started',
  finished: 'finished',
};

const initialState = {
  isConversationModelOpen: false,
  conversationStatus: RDConversationStatus.waiting,
  currentOpenTokSessionId: null,
  currentConversation: null,
  partnerCountdownSeconds: 12,
  isConversationNotificationModalOpen: false,
  notificationUser: null,
  notificationTopic: null,
};

export const conversation = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_CONVERSATION_NOTIFICATION_MODAL: {
      const { isOpen } = payload;
      return {
        ...state,
        isConversationNotificationModalOpen: isOpen,
      };
    }

    case SHOW_CONVERSATION_NOTIFICATION_MODAL: {
      const { user, topic } = payload;
      return {
        ...state,
        isConversationNotificationModalOpen: true,
        notificationTopic: topic,
        notificationUser: user,
      };
    }

    case CLEAR_CONVERSATION_NOTIFICATION_MODAL: {
      return {
        ...state,
        isConversationNotificationModalOpen: false,
        notificationTopic: null,
        notificationUser: null,
      };
    }

    case CLEAR_CURRENT_CONVERSATION: {
      return {
        ...state,
        currentConversation: null,
      };
    }

    case OPEN_CONVERSATION_MODAL: {
      return {
        ...state,
        partnerCountdownSeconds: RDConfig.partnerWaitSeconds,
        isConversationModalOpen: true,
        conversationStatus: RDConversationStatus.waiting,
        isConversationNotificationModalOpen: false,
      };
    }

    case CLOSE_CONVERSATION_MODAL: {
      return {
        ...state,
        isConversationModalOpen: false,
      };
    }

    case UPDATE_CONVERSATION_STATUS: {
      const { status, openTokSessionId } = payload;
      return {
        ...state,
        conversationStatus: status,
        openTokSessionId,
      };
    }

    case SET_CURRENT_CONVERSATION: {
      const { conversation } = payload;
      return {
        ...state,
        currentConversation: conversation,
      };
    }

    default:
      return state;
  }
};
