export const RDAppMode = {
  staging: 'staging',
  production: 'production',
};

let appMode = RDAppMode.staging;
let stripePublicKey = 'pk_test_51ISDAcHQBhmmCgiXktNw4QINF0KyNjkyBws5zY0DC2MQLkkaNdS85WRxtY5NxgTaXCuYsmWs8GypobLt5HmsuPXb00vm0EZNnx';
const amplitudePublicKey = '5237bf8a5116fb3c683415371d370eb6';
let isEmbed = false;

if (process.env.REACT_APP_MODE === 'STAGING') {
  appMode = RDAppMode.staging;
  stripePublicKey = 'pk_test_51ISDAcHQBhmmCgiXktNw4QINF0KyNjkyBws5zY0DC2MQLkkaNdS85WRxtY5NxgTaXCuYsmWs8GypobLt5HmsuPXb00vm0EZNnx';
} else if (process.env.REACT_APP_MODE === 'PRODUCTION') {
  appMode = RDAppMode.production;
  stripePublicKey = 'pk_live_51ISDAcHQBhmmCgiXKiibtbs7OAvVs96UrtgAQQfTBo4WpRMjLLfrLe4tGfo20CNyJxnyqR9njKqZ0syAPy274h8Y00XM0dUIdV';
}

console.log(process.env);
if (process.env.REACT_APP_IS_EMBED === 'true') {
  console.log('In Embed Mode');
  isEmbed = true;
}

export const RDConfig = {
  appMode,
  partnerWaitSeconds: 30,
  stripePublicKey,
  webPushServerKey: 'BGtsUnfOM73qfdyfmZPl4TPUdpDV73CoyZFFlpSZriDtAXV8qFfmqGbhM25KdsF6wbiBCE5LdAMx7yXpPqCwr5o',
  amplitudePublicKey,
  isEmbed,
};
