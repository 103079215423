import { useEffect, useState } from 'react';
import { axiosInstance } from '../api/api';
import { RDConversation } from '../models/RDConversation';
import { filterLocalChannelData } from '../player/RDPlayer';

const useAudioVisualDataForConversation = (conversation:RDConversation|null) => {
  const [channel1Data, setChannel1Data] = useState([]);
  const [channel2Data, setChannel2Data] = useState([]);
  const [visualsReady, setVisualsReady] = useState(false);
  const [talkRatios, setTalkRatios] = useState<null|number[]>(null);

  const fetchAudioVisualDataForConversation = async (pConversation:RDConversation) => {
    const res = await axiosInstance.get(pConversation.audioVisualDataUrl);

    let fetchedChannel1Data = res.data.channel1Data;
    let fetchedChannel2Data = res.data.channel2Data;

    const p1ActiveFrames = fetchedChannel1Data.filter((el:number) => el > 0.1).length;
    const p2ActiveFrames = fetchedChannel2Data.filter((el:number) => el > 0.1).length;
    const p1Ratio = p1ActiveFrames / (p1ActiveFrames + p2ActiveFrames);
    const p2Ratio = p2ActiveFrames / (p1ActiveFrames + p2ActiveFrames);

    setTalkRatios([p1Ratio, p2Ratio]);

    const numberOfSamples = fetchedChannel1Data.length * 0.5;

    fetchedChannel1Data = filterLocalChannelData(fetchedChannel1Data, numberOfSamples);
    fetchedChannel2Data = filterLocalChannelData(fetchedChannel2Data, numberOfSamples);

    setChannel1Data(fetchedChannel1Data);
    setChannel2Data(fetchedChannel2Data);
    setVisualsReady(true);
  };

  useEffect(() => {
    if (conversation && conversation.audioVisualDataUrl) {
      console.log(`Fetching audio visual data for conversation: ${conversation.conversationId}`);
      fetchAudioVisualDataForConversation(conversation).catch(console.error);
    }
  }, [conversation]);
  return [visualsReady, channel1Data, channel2Data, talkRatios] as const;
};

export default useAudioVisualDataForConversation;
