import React, {
  useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import Linkify from 'react-linkify';
import { RDIconURIs } from '../utilities/RDIconURIs';
import { RDColors } from '../utilities/RDColors';
import { RDTextUtils } from '../utilities/RDTextUtils';
import RDAudioPlayer from '../player/RDAudioPlayer';
import RDSmallLoader from '../global/RDSmallLoader';
import RDBounceLoader from '../global/RDBounceLoader';
import { RDRouteDirectory } from '../utilities/RDRouteDirectory';
import { axiosInstance, isResponseSuccess, RDApi } from '../api/api';
import { getCurrentPath, getCurrentQuery } from '../navigation/selectors';
import { RDGeneralUtils } from '../utilities/RDGeneralUtils';
import RDLogo from '../global/RDLogo';
import { RDConfig } from '../config';
import useCommentsForConversation from '../hooks/useComentsForConversation';
import useConversation from '../hooks/useConversation';
import useAudioVisualDataForConversation from '../hooks/useAudioVisualDataForConversation';
import useInsightsForConversation from '../hooks/useInsightsForConversation';

const Frame = styled.div`
  width: calc(100% - 24px);
  background-color: transparent;
  display: flex;
  padding:12px;
  align-items: center;
  justify-content: center;
  
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
    padding-top: 10px;
    padding-bottom:10px;
  }
`;

const PlayerFrame = styled.div`
  max-width: 860px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  min-height: 150px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 1px ${RDColors.hexColorWithOpacity('#000000', 0.1)};
`;

const LoadingStatusView = styled.div`
  height: 100%;
  min-height:150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingStatusMessage = styled.div`
  font-size: 18px;
  font-family: "AvenirBold", sans-serif;
  color: ${RDColors.sectionTitleGray};
`;

const UpperPanel = styled.div`
  display: flex;
  background-color: ${RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.0)};
  margin-bottom:4px;
`;

const UserPanel = styled.div`
  margin-bottom:2px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.0)};
`;

const SubUserPanel = styled.div`
  display: flex;
  align-items: center;
  color: black;
`;

const SubUserPanelCreator = styled(SubUserPanel)`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const PlayButton = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 40px;
  background-color: ${RDColors.purple};
  background-image: linear-gradient(to top right, ${RDColors.purple}, ${RDColors.pink});
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-right: 8px;
  
  @media only screen and (max-width: 540px) {
    display:  none;
  }
`;

const PlayButtonSmallScreen = styled.div`
  height: 70px;
  width: 70px;
  border-radius: 40px;
  background-color: ${RDColors.purple};
  background-image: linear-gradient(to top right, ${RDColors.purple}, ${RDColors.pink});
  display: flex;
  flex-shrink: 0;
  margin-top: 4px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-right: auto;
  margin-left:auto;
  margin-bottom: 8px;
  order:  -1;

  @media only screen and (min-width: 540px) {
    display:  none;
  }
  
`;

const PlayIcon = styled.img`
  width: 35px;
  height: 35px;
  padding-left: 2px;
`;

const PauseIcon = styled.img`
  width: 35px;
  height: 35px;
`;

const WaveField = styled.canvas`
  width: 100%;
  height: 80px;
  background-color: transparent;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const ConversationTitle = styled.div`
  font-family: 'Avenir', sans-serif;
  font-size: 16px;
  text-align:left;
  font-weight: 500;
  color: ${RDColors.sectionTitleGray};
  margin-bottom: 2px;
`;

const DD = styled(ConversationTitle)`
  font-family: 'Avenir', sans-serif;
  font-size: 14px;
  text-align:left;
  font-weight: 500;
  color: ${RDColors.hexColorWithOpacity(RDColors.sectionTitleGray, 0.75)};
  margin-bottom: 2px;
`;

const SectionSubtitle = styled(Linkify)`
  font-family: 'Avenir', sans-serif;
  font-size: 14px;
  text-align:left;
  font-weight: 500;
  color: ${RDColors.hexColorWithOpacity(RDColors.sectionTitleGray, 0.7)};
  margin-top: 0px; 
  margin-bottom: 5px;
`;

const ProfileImageView = styled.img`
  background: darkgray;
  width: 20px;
  height: 20px;
  border: solid 4px;
  border-radius: 20px;
  object-fit: cover;
  margin-right: 6px;
  
  @media only screen and (max-width: 600px) {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border: solid 3px;
  }
`;
const ProfileText = styled.div`
  font-size: 18px;
  text-align: left;
  font-family: 'Avenir';
  display: inline-block;
  line-height: 36px;
  float: left;
  
  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

const CreatorImageView = styled(ProfileImageView)`
  display:inline-block;
  float:left;
  border-color: ${RDColors.hexColorWithOpacity(RDColors.pink, 0.8)};
`;

const PartnerImageView = styled(ProfileImageView)`
  display:inline-block;
  float:left;
  border-color: ${RDColors.hexColorWithOpacity(RDColors.blue, 0.8)};
`;

const LogoHolder = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  @media only screen and (max-width: 540px) {
    display:  none;
  }
`;

const LogoHolderSmallScreen = styled(LogoHolder)`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  text-decoration:none !important;
  cursor: pointer;
  @media only screen and (min-width: 540px) {
    display:  none;
  }
  
  
`;

const RDLogoStyled = styled(RDLogo)`
`;
const ConversationLink = styled.a`
  text-decoration: none; !important;
  color: black;
  display:flex;
`;

const RadicalTitle = styled.div`
  font-family: "ModerneSans", sans-serif;
  letter-spacing: 4px;
  text-align: left;
  height: 14px;
  font-size: 18px;
  display: inline-block;
  margin-left:6px;
`;

const TopPanel = styled.div`
  display:flex;
`;

const TopPanelRightContent = styled.div`
  width: calc(100% - 50px);
  margin-left:2px;
  
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const TimerDiv = styled.div`
   width: 100%;
   z-index: 5;
   margin-top: -30px;
   position:relative;
   pointer-events: none;
`;

const DurationLabel = styled.div`
  padding: 3px 5px 1px 5px;
  background-color: ${RDColors.hexColorWithOpacity('#000000', 0.5)};
  font-family: 'Avenir', sans-serif;
  color: white;
  font-size: 13px;
  text-align: center;
  display: inline-block;
  margin-top: 6px;
  z-index:5;
  float: right;
  border-bottom-right-radius: 10px;
`;

const CurrentPositionLabel = styled.div`
  padding: 3px 5px 1px 5px;
  background-color: ${RDColors.hexColorWithOpacity('#000000', 0.5)};
  font-family: 'Avenir', sans-serif;
  color: white;
  font-size: 13px;
  text-align: center;
  z-index:5;
  display: inline-block;
  margin-top: 6px;
  border-bottom-left-radius: 10px;
`;

const CommentsTray = styled.div`
   width: 100%;
   height: 30px;
   position: relative;
`;

const CommentProfileImageView = styled.img`
  background: darkgray;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  object-fit: cover;
  margin-top:3px;
  position:absolute;
  transform-origin: center;
  z-index: 9;
  margin-left: ${(props) => (props.xPosition ? `${props.xPosition}px` : '0px')};
  border-width: 0px;
  border-color: ${RDColors.purple};
  border-style: solid;
  cursor: pointer;
  
  ${(props) => props.isAnimating && `
    animation: rotation 2s infinite linear reverse;
    @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `}
`;

const HoverTitle = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  color: ${RDColors.purpleBlue};
`;

const HoverCommentView = styled.div`
  font-family: Avenir;
  font-size: 11px;
  border-radius: 10px;
  color:black;
  background-color: ${RDColors.hexColorWithOpacity('#ffffff', 0.65)};
  height: auto;
  display: inline-block;
  max-width: 650px;
  padding: 8px;
  overflow-wrap: break-word;
  z-index: 1000;
  pointer-events: none;
  backdrop-filter: blur(5px);
  position:absolute;
  bottom: 30px;
  box-shadow: 2px 2px 10px 1px ${RDColors.hexColorWithOpacity('#000000', 0.1)};
  left: ${(props) => (props.xPosition ? `${props.xPosition + 5}px` : '5px')};
`;

const InsightsHolder = styled.div`
  width:100%;
  border-radius: 10px;
  margin-top: 10px;
`;

const InsightsHolderTitle = styled.div`
   font-size: 14px;
   color: ${RDColors.sectionTitleGray};
   font-weight: 500;
   margin-: 500;
   margin-bottom: 4px;
   display: inline-block;
   margin-top: 4px;
   margin-right: 6px;
   font-family: AvenirBold, sans-serif;
`;

const InsightCellHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EndMessageUsername = styled.span`
  color: ${RDColors.hexColorWithOpacity('#000000', 0.35)};
  font-family: AvenirBold, sans-serif;
`;

const EndMessageLink = styled.a`
  color: ${RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.45)};
  font-family: AvenirBold, sans-serif;
  text-decoration: none;
`;

const EndMessage = styled.div`
  margin-top:2px;
  text-align: center;
  font-size: 12px;
  color: ${RDColors.hexColorWithOpacity('#000000', 0.3)};
  font-family: AvenirLight, sans-serif;
`;

const AreaPill = styled.div`
  display: inline-block;
  border-radius: 20px;
  padding: 2px;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: ${(props) => (props.selected ? 'white' : RDColors.sectionTitleGray)};
  background-color: ${(props) => (props.selected ? RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.85) : RDColors.hexColorWithOpacity('#ffffff', 0.4))};
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 8px;
  margin-bottom: 6px;
  border-style: solid;
  border-color: ${(props) => (props.selected ? RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.85) : RDColors.hexColorWithOpacity('#000000', 0.08))};
  border-width: 1px;
  
  &:hover {
    background-color: ${(props) => (props.selected ? RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.85) : RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.35))};
    border-color: ${(props) => (props.selected ? RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.85) : RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.35))};
  }
`;

const PlayerComponents = styled.div`
  @media only screen and (max-width: 600px) {
    order:  -1;
  }
`;

const trackConversationListenOnRadical = async (conversation) => {
  const data = { conversation_uuid: conversation.uuid };
  const res = await axiosInstance.post(RDApi.markListenForConversation, data);
  if (isResponseSuccess(res)) {
    console.log('Successfully tracked listen on Radical');
  } else {
    console.log('Failed to track listen');
  }
};

function drawChannelData(context, canvas, channelData, channel) {
  if (!channelData || channelData.length === 0) {
    return;
  }

  const xUnit = canvas.width / channelData.length;
  let runningLength = 0;

  if (channel === 1) {
    context.fillStyle = RDColors.hexColorWithOpacity(RDColors.pink, 0.3);
  } else {
    context.fillStyle = RDColors.hexColorWithOpacity(RDColors.blue, 0.3);
  }

  context.beginPath();
  context.moveTo(0, canvas.height);

  for (let i = 0; i < channelData.length; i += 1) {
    runningLength += xUnit;

    const xPoint = runningLength;
    const yPoint = canvas.height - (canvas.height * channelData[i]);

    let yPointNext = 0;
    if ((i + 1) < channelData.length) {
      yPointNext = canvas.height - (canvas.height * channelData[i + 1]);
    }

    const xC = (runningLength + (runningLength + xUnit)) / 2;
    const yC = ((yPoint + yPointNext) / 2);

    context.quadraticCurveTo(xPoint, yPoint, xC, yC);
  }

  context.lineTo(canvas.width, canvas.height);
  context.fill();
}

function drawActiveWordMarkers(context, pCanvas, pSelectedWord, fullInsights, archive) {
  const wordData = fullInsights[pSelectedWord];
  if (!wordData) {
    return;
  }
  for (let i = 0; i < wordData.items.length; i += 1) {
    const item = wordData.items[i];
    const { startTime } = item;

    const xUnit = pCanvas.width / (archive.durationMs / 1000);

    const xPos = startTime * xUnit;
    const yPos = 40;

    context.fillStyle = RDColors.hexColorWithOpacity(RDColors.purpleBlue, 0.75);
    context.beginPath();
    context.arc(xPos, yPos, 10, 0, 2 * Math.PI);
    context.fill();
  }
}

function drawChannelPlayback(context, canvas, channelData, channel, progress) {
  if (!channelData || channelData.length === 0 || progress <= 0) {
    return;
  }

  if (channel === 1) {
    context.fillStyle = RDColors.hexColorWithOpacity(RDColors.pink, 0.55);
  } else {
    context.fillStyle = RDColors.hexColorWithOpacity(RDColors.blue, 0.55);
  }

  const xUnit = canvas.width / channelData.length;
  let runningLength = 0;
  let mouseTransitionComplete = false;

  context.beginPath();
  context.moveTo(0, canvas.height);

  for (let i = 0; i < channelData.length; i += 1) {
    runningLength += xUnit;

    const xPoint = runningLength;
    const yPoint = canvas.height - (canvas.height * channelData[i]);

    let yPointNext = 0;
    if ((i + 1) < channelData.length) {
      yPointNext = canvas.height - (canvas.height * channelData[i + 1]);
    }

    const xC = (runningLength + (runningLength + xUnit)) / 2;
    const yC = ((yPoint + yPointNext) / 2);

    if (runningLength > progress && !mouseTransitionComplete) {
      mouseTransitionComplete = true;
      context.quadraticCurveTo(xPoint, yPoint, xC, yC);
      context.lineTo(xPoint + xUnit / 2, canvas.height);
      context.fill();
    } else {
      context.quadraticCurveTo(xPoint, yPoint, xC, yC);
    }
  }
}

// const registerEmbed = async (embedUrl, conversationUUID) => {
//   try {
//     const res = await axiosInstance.post(RDApi.registerEmbed, { embedUrl, conversationUUID });
//     if (isResponseSuccess(res)) {
//       console.log('Successful registration');
//     } else {
//       console.log('Failed registration - server error');
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

let isMounted;

const RDEmbeddedPlayer = ({
  query,
  pathname,
  pConversationUUID,
  pIsProd,
  themeLimit,
  isEmbed = true,
}) => {
  let conversationUUID = null;
  const pathCheck = RDRouteDirectory.isPathForEmbeddedConversation(pathname);
  if (pathCheck.isRouteEmbeddedConversation) {
    conversationUUID = pathCheck.conversationUUID;
  } else if (pConversationUUID) {
    conversationUUID = pConversationUUID;
  }

  const audioPlayer = useRef(new RDAudioPlayer());
  const widthRef = useRef(0);
  const heightRef = useRef(0);
  const visualProgressRef = useRef(0);
  const didTrackListenRef = useRef(false);

  const [isFetchingConversation, archive] = useConversation(conversationUUID, pIsProd);
  const elementRef = useRef(null);
  const canvasRef = useRef(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(true);
  const [isMouseOver, setMouseOver] = useState(false);
  const [progress, setProgress] = useState(0);
  const [hoverComment, setHoverComment] = useState(null);
  const [hoverX, setHoverX] = useState(0);

  const [visualsReady, channel1Data, channel2Data] = useAudioVisualDataForConversation(archive);
  const [
    areInsightsAvailable,
    fullInsights,
    nounArray,
  ] = useInsightsForConversation(archive, query, themeLimit);
  const [, comments] = useCommentsForConversation(archive, pIsProd);
  const [activeWord, setActiveWord] = useState(null);

  let queryBackgroundColor = null;
  if (query.bgColor && query.bgColor.length >= 6) {
    queryBackgroundColor = query.bgColor;
    document.body.style.backgroundColor = `#${query.bgColor}`;
  }

  const RDPlayer = audioPlayer.current;
  RDPlayer.getAudioPlayer().ontimeupdate = () => {
    if (!isMounted) {
      console.log('Blocking time updates on player.');
    }
    setProgress(RDPlayer.getCurrentTime());
    if (!isMouseOver && canvasRef.current) {
      const vProgress = (
        RDPlayer.getCurrentTime() / (archive.durationMs / 1000)
      ) * canvasRef.current.width;
      visualProgressRef.current = vProgress;
    }

    const timeElapsedRatio = (RDPlayer.getCurrentTime() / (archive.durationMs / 1000));
    const { currentTime } = RDPlayer;
    if (!didTrackListenRef.current && (currentTime > 20 || timeElapsedRatio > 0.15)) {
      didTrackListenRef.current = true;

      // Log event on Amplitude
      if (archive) {
        const amplitudeEventProperties = {
          conversation: archive,
          user: 'EmbeddedPlayer',
        };
        amplitude.getInstance().logEvent('RDWeb_Conversation_Listen', amplitudeEventProperties);
      }

      // Track listen on Radical
      if (archive) {
        trackConversationListenOnRadical(archive, null).catch(console.error);
      }
    }
  };

  RDPlayer.getAudioPlayer().oncanplay = () => {
    if (!isMounted) {
      console.log('Blocking time updates on player.');
    }
    setIsAudioLoading(false);
  };

  RDPlayer.getAudioPlayer().onended = () => {
    if (!isMounted) {
      console.log('Blocking time updates on player.');
    }
    setProgress(archive.durationMs / 1000);
  };

  const resized = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.style.width = '100%';
      const width = getComputedStyle(canvas)
        .getPropertyValue('width')
        .slice(0, -2);
      const context = canvas.getContext('2d');
      const ratio = RDGeneralUtils.getPixelRatio(context);
      widthRef.current = parseInt(width, 10);
      canvas.width = width * ratio;
      setWindowWidth(width * ratio);
    }
  };

  let requestId;

  useEffect(() => {
    isMounted = true;
    window.addEventListener('resize', resized);
    amplitude.getInstance().init(RDConfig.amplitudePublicKey);

    if (archive && !archive.fetchedArchiveAssets && audioPlayer.current) {
      audioPlayer.current.loadSource(archive.getAudioLink());
      archive.fetchedArchiveAssets = true;

      const amplitudeEventProperties = {
        conversation: archive,
      };
      amplitude.getInstance().logEvent('RDEmbed_LoadedConversation', amplitudeEventProperties);
    }

    return () => {
      isMounted = false;
    };
  }, [
    archive,
  ]);

  useEffect(() => {
    isMounted = true;
    const render = () => {
      if (!isMounted) {
        console.log('Blocking renders');
        return;
      }

      const canvas = canvasRef.current;

      if (!canvas) {
        return;
      }

      if (canvas) {
        const context = canvas.getContext('2d');
        const ratio = RDGeneralUtils.getPixelRatio(context);
        const width = getComputedStyle(canvas)
          .getPropertyValue('width')
          .slice(0, -2);
        const height = getComputedStyle(canvas)
          .getPropertyValue('height')
          .slice(0, -2);
        widthRef.current = parseInt(width, 10);
        heightRef.current = parseInt(height, 10);
        canvas.width = width * ratio;
        canvas.height = height * ratio;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
      }

      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      drawChannelData(context, canvas, channel1Data, 1);
      drawChannelData(context, canvas, channel2Data, 2);
      drawChannelPlayback(context, canvas, channel1Data, 1, visualProgressRef.current);
      drawChannelPlayback(context, canvas, channel2Data, 2, visualProgressRef.current);

      if (selectedWord && selectedWord[2] === 'theme') {
        drawActiveWordMarkers(context, canvas, selectedWord[0], fullInsights, archive);
      }
      requestId = requestAnimationFrame(render);
    };

    render();

    if (elementRef.current) {
      const postMessage = {
        message: 'radicalIFrameHeightUpdate',
        height: document.body.getBoundingClientRect().height + 75,
      };

      if (archive && archive.uuid) {
        postMessage.elementId = `radical_embed_${archive.uuid}`;
      }
      window.top.postMessage(postMessage, '*');
    }

    return () => {
      isMounted = false;
    };
  }, [archive, selectedWord, visualsReady, windowWidth, areInsightsAvailable]);

  const canvas = canvasRef.current;
  const view = (
    <Frame bgc={queryBackgroundColor} ref={elementRef}>
      <PlayerFrame>
        {!archive && !isFetchingConversation
          && (
          <LoadingStatusView>
            <LoadingStatusMessage>Conversation is not available</LoadingStatusMessage>
          </LoadingStatusView>
          )}

        {!archive && isFetchingConversation
          && (
          <LoadingStatusView>
            <RDBounceLoader style={{ marginBottom: 4 }} />
            <LoadingStatusMessage>Loading Conversation</LoadingStatusMessage>
          </LoadingStatusView>
          )}

        {archive
          && (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <PlayButtonSmallScreen onClick={() => {
              setIsPlaying(!isPlaying);
              if (RDPlayer.isPaused()) {
                RDPlayer.play();
              } else {
                RDPlayer.pause();
              }
            }}
            >
              {(!isPlaying && !isAudioLoading) && <PlayIcon src={RDIconURIs.play} />}
              {(isPlaying && !isAudioLoading) && <PauseIcon src={RDIconURIs.pause} />}
              {isAudioLoading && <RDSmallLoader free style={{ position: 'relative' }} top={0} />}
            </PlayButtonSmallScreen>

            <TopPanel>
              <PlayButton onClick={() => {
                setIsPlaying(!isPlaying);
                if (RDPlayer.isPaused()) {
                  RDPlayer.play();
                } else {
                  RDPlayer.pause();
                }
              }}
              >

                {(!isPlaying && !isAudioLoading) && <PlayIcon src={RDIconURIs.play} />}
                {(isPlaying && !isAudioLoading) && <PauseIcon src={RDIconURIs.pause} />}
                {isAudioLoading && <RDSmallLoader free style={{ position: 'relative' }} top={0} />}

              </PlayButton>
              <TopPanelRightContent>
                <UserPanel>
                  <SubUserPanelCreator>
                    <CreatorImageView src={archive.creator.getProfileImageUrl()} />
                    <ProfileText>{archive.creator.getDisplayUsername()}</ProfileText>
                  </SubUserPanelCreator>

                  <SubUserPanel>
                    <PartnerImageView src={archive.partner.getProfileImageUrl()} />
                    <ProfileText>{archive.partner.getDisplayUsername()}</ProfileText>
                  </SubUserPanel>

                  <ConversationLink style={{ display: 'block', marginLeft: 'auto' }} href={`https://radical.overtonelabs.com/conversation/${archive.uuid}`} target="_blank">
                    <LogoHolder>
                      <RDLogoStyled width={24} height={24} isAnimated={false} />
                      <RadicalTitle>RADICAL</RadicalTitle>
                    </LogoHolder>
                  </ConversationLink>
                </UserPanel>

                <UpperPanel>
                  <ConversationTitle>{archive.getDisplayTitle()}</ConversationTitle>
                </UpperPanel>
                {archive.description && isEmbed
              && (
                <DD>
                  <SectionSubtitle componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a style={{ color: RDColors.purpleBlue, fontFamily: 'Avenir' }} target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                  >
                    {archive.description}
                  </SectionSubtitle>
                </DD>
              )}
              </TopPanelRightContent>

            </TopPanel>

            <PlayerComponents>
              <WaveField
                resize="true"
                ref={canvasRef}
                onClick={(e) => {
                  const width = widthRef.current;
                  const rect = e.target.getBoundingClientRect();
                  const calcProgress = (
                    (e.clientX - rect.left) / width
                  ) * archive.durationMs * 0.001;
                  RDPlayer.seekAndPlay(calcProgress);
                  setIsPlaying(true);
                }}
                onMouseMove={(e) => {
                  const width = widthRef.current;
                  const rect = e.target.getBoundingClientRect();
                  const mouseX = ((e.clientX - rect.left) / width) * canvas.width;

                  const calcProgress = (
                    (e.clientX - rect.left) / width
                  ) * archive.durationMs * 0.001;
                  visualProgressRef.current = mouseX;
                  setProgress(calcProgress);
                }}
                onMouseOut={() => {
                  if (canvas) {
                    const vProgress = (
                      RDPlayer.getCurrentTime() / (archive.durationMs / 1000)
                    ) * canvas.width;
                    visualProgressRef.current = vProgress;
                  }

                  setProgress(RDPlayer.getCurrentTime());
                  setMouseOver(false);
                }}
                onMouseEnter={() => {
                  setMouseOver(true);
                }}
              />
              <TimerDiv>
                <CurrentPositionLabel>
                  {RDTextUtils.convertSecondsToTimerDisplay(progress)}
                </CurrentPositionLabel>
                <DurationLabel>
                  {RDTextUtils.convertSecondsToTimerDisplay(archive.durationMs / 1000)}
                </DurationLabel>
              </TimerDiv>

              { (comments.length > 0)

            && (
            <CommentsTray
              onMouseMove={() => {
              }}
            >

              {comments.map((comment) => {
                const commentXRatio = (comment.timestamp * 1000) / archive.durationMs;
                let commentXPosition = 0;
                if (canvasRef.current != null) {
                  const context = canvasRef.current.getContext('2d');
                  const pixelRatio = RDGeneralUtils.getPixelRatio(context);
                  commentXPosition = (
                    commentXRatio * canvasRef.current.width * (1 / pixelRatio)
                  ) - 12; // 12 is half the width of profile circle
                  if (commentXPosition < 0) {
                    commentXPosition = 0;
                  }
                }

                return (
                  <CommentProfileImageView
                    onClick={() => {
                      setIsPlaying(true);
                      seekAndPlay(comment.timestamp - 10);
                    }}
                    key={comment.commentId}
                    isAnimating={false}
                    onMouseEnter={() => {
                      const commentXRatio = (comment.timestamp * 1000) / archive.durationMs;
                      let commentXPosition = 0;
                      if (canvasRef.current != null) {
                        const context = canvasRef.current.getContext('2d');
                        const pixelRatio = RDGeneralUtils.getPixelRatio(context);
                        commentXPosition = (
                          commentXRatio * canvasRef.current.width * (1 / pixelRatio)
                        ) - 12; // 12 is half the width of profile circle
                        if (commentXPosition < 0) {
                          commentXPosition = 0;
                        }

                        if (commentXPosition + 220 > (canvas.width / pixelRatio)) {
                          commentXPosition -= 240;
                        }

                        setHoverX(Math.round(commentXPosition));
                        setHoverComment(comment);
                      }
                    }}
                    onMouseOut={() => {
                      setHoverComment(null);
                      setHoverX(0);
                    }}
                    xPosition={commentXPosition}
                    src={comment.user.getThumbnail()}
                  />
                );
              })}
              {hoverComment
              && (
              <HoverCommentView xPosition={hoverX}>
                <HoverTitle>
                  {hoverComment.user.username}
                  <span style={{ fontSize: 9, color: RDColors.sectionTitleGray }}>
                    {' '}
                    @
                    {' '}
                    {RDTextUtils.convertSecondsToTimerDisplay(hoverComment.timestamp)}
                  </span>
                </HoverTitle>
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a style={{ color: RDColors.purpleBlue }} target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
                >
                  {hoverComment.text}
                </Linkify>
              </HoverCommentView>
              )}
            </CommentsTray>
            )}
            </PlayerComponents>

            {areInsightsAvailable
          && (
          <InsightsHolder>
            <InsightCellHolder>
              <InsightsHolderTitle>Themes: </InsightsHolderTitle>
              {nounArray.map((noun) => (
                <AreaPill
                  key={noun}
                  selected={noun === activeWord}
                  onClick={() => {
                    if (activeWord === noun) {
                      setActiveWord(null);
                      setSelectedWord(null);
                    } else {
                      setActiveWord(noun);
                      setSelectedWord(noun.concat(['theme']));
                    }
                  }}
                >
                  {noun[0]}
                </AreaPill>
              ))}
            </InsightCellHolder>
          </InsightsHolder>
          )}
            <ConversationLink href={`https://radical.overtonelabs.com/conversation/${archive.uuid}`} target="_blank">
              <LogoHolderSmallScreen>
                <RDLogoStyled width={24} height={24} isAnimated={false} />
                <RadicalTitle>RADICAL</RadicalTitle>
              </LogoHolderSmallScreen>
            </ConversationLink>
          </div>
          )}

        {archive && isEmbed
        && (
        <EndMessage>
          Talk to and listen to more conversations by
          {' '}
          <EndMessageUsername>{archive.creator.username}</EndMessageUsername>
          {' '}
          and
          {' '}
          <EndMessageUsername>{archive.partner.username}</EndMessageUsername>
          {' '}
          on
          {' '}
          <EndMessageLink href={`https://radical.overtonelabs.com/conversation/${archive.uuid}`} target="_blank">Radical</EndMessageLink>
        </EndMessage>
        )}
      </PlayerFrame>
    </Frame>
  );
  return view;
};

const mapStateToProps = (state) => ({
  query: getCurrentQuery(state),
  pathname: getCurrentPath(state),
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(RDEmbeddedPlayer);
