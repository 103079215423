import {
  CHANGE_NAV_SECTION,
  LOG_OUT, SAVE_PRE_SEARCH_ROUTE,
  TOGGLE_WEB_PUSH_MODAL,
} from './actions';

import { NavigationSection } from './NavigationView';

const initialState = { selectedNavSection: NavigationSection.TALK, isWebPushModalOpen: false, preSearchRoute: null };

export const navigation = (state = initialState, action) => {
  const { type, payload } = action;
  // console.log(`NAV REDUCER: ${type}`)
  switch (type) {
    case CHANGE_NAV_SECTION: {
      const { section } = payload;
      return {
        ...state,
        selectedNavSection: section,
      };
    }

    case SAVE_PRE_SEARCH_ROUTE: {
      const { route } = payload;
      return {
        ...state,
        preSearchRoute: route,
      };
    }

    case TOGGLE_WEB_PUSH_MODAL: {
      const { visible } = payload;
      return {
        ...state,
        isWebPushModalOpen: visible,
      };
    }

    default:
      return state;
  }
};
