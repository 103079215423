import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {RDColors} from '../utilities/RDColors.js'


let rotationDuration = 3.0;
let driftDim = 1.25
let delayFactor = 0.25
let driftDuration = 1.0;

let Holder= styled.div`
    layout: absolute;
    position: relative;
    display: inline-block;
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    background-color: clear;
    float: left;
    
    ${ props => props.isAnimated && `
      animation: spin ${rotationDuration}s infinite linear;
      @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
      }`}
    `
let InnerDot= styled.div`
    position: absolute;
    height: ${props => props.dotDim}px;
    width: ${props => props.dotDim}px;
    border-radius: ${props => props.dotDim/2.0}px;
    background-color: ${RDColors.pink};
    background: radial-gradient(${RDColors.purple} 10%, ${RDColors.pink});
    top: ${props => props.dotCenter}px;
    left: ${props => props.dotCenter}px;
    
    ${ props => props.isAnimated && `
      animation: pulse 2.0s infinite alternate ease-in-out;
      @keyframes pulse {
        from { transform: scale(0.8); }
        to { transform: scale(1.2); }
      }`}
    `
const GrayDot= styled.div`
    position: absolute;
    height: ${props => props.dotDim}px;
    width: ${props => props.dotDim}px;
    border-radius: ${props => props.dotDim/2.0}px;
    background-color: ${RDColors.eugeneGray};
    top: ${props => props.dotCenter}px;
    left: ${props => props.dotCenter}px;`

let GrayDotBottomRight= styled(GrayDot)`
    top: ${props => (props.dotCenter + props.armDim)}px;
    left: ${props => (props.dotCenter + props.armDim)}px;
    ${ props => props.isAnimated && `
      animation-delay: 1s;
      animation: drift-br ${driftDuration + 0*delayFactor}s linear infinite alternate;
      @keyframes drift-br {
        from { transform: translate(${-driftDim}px,${-driftDim}px); }
        to { transform: translate(${driftDim}px,${driftDim}px);}
      }`}
    `

let GrayDotTopRight= styled(GrayDot)`
    top: ${props => (props.dotCenter - props.armDim)}px;
    left: ${props => props.dotCenter + props.armDim}px;
    ${ props => props.isAnimated && `
      animation-delay: 2s;
      animation: drift-tr ${driftDuration + 1*delayFactor}s linear infinite alternate;
      @keyframes drift-tr {
        from { transform: translate(${-driftDim}px,${+driftDim}px); }
        to { transform: translate(${+driftDim}px,${-driftDim}px);}
      }`}
    `

let GrayDotTopLeft= styled(GrayDot)`
    top: ${props => props.dotCenter - props.armDim}px;
    left: ${props => props.dotCenter- props.armDim}px;
    ${ props => props.isAnimated && `
      animation-delay: 3s;
      animation: drift-tl ${driftDuration + 2*delayFactor}s linear infinite alternate;
      @keyframes drift-tl {
        from { transform: translate(${driftDim}px,${driftDim}px); }
        to { transform: translate(${-driftDim}px,${-driftDim}px);}
      }`}
    `

let GrayDotBottomLeft= styled(GrayDot)`
    top: ${props => props.dotCenter + props.armDim}px;
    left: ${props => props.dotCenter - props.armDim}px;
    ${ props => props.isAnimated && `
      animation: drift-bl ${driftDuration + 3*delayFactor}s linear infinite alternate;
      @keyframes drift-bl {
        from { transform: translate(${+driftDim}px,${-driftDim}px); }
        to { transform: translate(${-driftDim}px,${+driftDim}px);}
      }`}
    `
const RDLogo = ({width, height, isAnimated=true, style}) => {

  let dotDim = Math.round(width*0.24)
  let dotCenter = Math.round((height/2.0 - dotDim/2.0))
  let armDim = Math.round(width*0.265)

  let logo = (
      <Holder isAnimated={isAnimated} style={style} height={height} width={width}>
        <InnerDot isAnimated={isAnimated} dotDim={dotDim} dotCenter={dotCenter}/>
        <GrayDotTopRight isAnimated={isAnimated} armDim={armDim} dotDim={dotDim} dotCenter={dotCenter} />
        <GrayDotBottomRight isAnimated={isAnimated} armDim={armDim} dotDim={dotDim} dotCenter={dotCenter}/>
        <GrayDotBottomLeft isAnimated={isAnimated} armDim={armDim} dotDim={dotDim} dotCenter={dotCenter}/>
        <GrayDotTopLeft isAnimated={isAnimated} armDim={armDim} dotDim={dotDim} dotCenter={dotCenter}/>
      </Holder>
  )
  return logo
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(RDLogo);
