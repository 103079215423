export const RDGeneralUtils = {

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  },

  getPixelRatio(context) {
    const backingStore = context.backingStorePixelRatio
        || context.webkitBackingStorePixelRatio
        || context.mozBackingStorePixelRatio
        || context.msBackingStorePixelRatio
        || context.oBackingStorePixelRatio
        || context.backingStorePixelRatio
        || 1;
    return (window.devicePixelRatio || 1) / backingStore;
  },

  updateCanvasRefForScreenPixelRatio(canvas) {
    const context = canvas.getContext('2d');
    const ratio = RDGeneralUtils.getPixelRatio(context);

    const width = getComputedStyle(canvas)
      .getPropertyValue('width')
      .slice(0, -2);

    const height = getComputedStyle(canvas)
      .getPropertyValue('height')
      .slice(0, -2);

    canvas.width = width * ratio;
    canvas.height = height * ratio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
  },
};
