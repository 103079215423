import { RDUser } from './RDUser';

export class RDTopic {
  constructor(json) {
    this.topicId = json.id;
    this.title = json.title;
    this.link1 = json.link_1;
    this.link2 = json.link_2;
    this.dateCreated = json.date_created;
    this.uuid = json.uuid;
    this.creator = new RDUser(json.creator);
    this.rawJson = json;
    this.initiator = null;
    this.isDeleted = json.deleted;
    if (json.active) {
      this.isActive = json.active;
    }

    // Local properties
    if (json.local_active) {
      this.isActive = json.local_active;
    }

    if (json.local_delete) {
      this.isDeleted = json.local_delete;
    }
  }

  isMine() {
    return this.creator.isMe();
  }

  didInitiate() {
    return this.initiator != null && this.initiator.isMe();
  }
}

RDTopic.openTopicUUID = 'ZolydsFapaM3A8TT';
RDTopic.openTopicId = 'Y7O';
